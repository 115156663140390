<template>
  <v-navigation-drawer
    v-model="drawerChild"
    :clipped="$vuetify.breakpoint.lgAndUp"
    :expand-on-hover="$vuetify.breakpoint.lgAndUp"
    :mini-variant="$vuetify.breakpoint.lgAndUp"
    ref="navDrawer"
    app
  >
    <vue-perfect-scrollbar class="drawer-menu--scroll" :settings="scrollSettings">
      <v-list dense>
        <template v-for="item in items">
          <v-list-group
            v-if="item.children"
            v-model="item.active"
            :key="item.text"
            :prepend-icon="item.icon"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="(child, i) in item.children">
              <v-list-item link dense 
                :to="child.ruta" 
                :key="`${child.text}${i}`"
                active-class="highlighted"
                :class="'/'+child.ruta === $route.path ? 'highlighted' : ''"
              >
                <v-list-item-icon v-if="child.icon && $refs.navDrawer.isMiniVariant" >
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="child.text"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="child.icon && !$refs.navDrawer.isMiniVariant">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-group>
          <!--Si no contiene grupo y tiene ruta-->
          <v-list-item dense :link="item.ruta?true:false"
            v-else
            :key="item.text" 
            :to="item.ruta?item.ruta:'#'"
            active-class="highlighted"
            :class="'/'+item.ruta === $route.path ? 'highlighted' : ''"
          >
            <v-list-item-icon v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'app-drawer',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    drawer: {
      type: Boolean,
      default: true
    },
    drawWidth: {
      type: [Number, String],
      default: "260"
    },
  },
  data: () => ({
    mini: false,
    drawerChild: null,
    scrollSettings: {
      maxScrollbarLength: 160
    },
    items: []
  }),
  computed: {
    currentUser(){
      return this.$store.getters.currentUser
    }
  },
  mounted () {
    this.initialize()

  },
  methods: {
    async initialize () {
      this.items = await this.$store.getters.currentUser.menus;
      if (Array.isArray(this.items) ) {
        this.drawerChild = this.currentUser.menus.length ? true : false
        var _this2 = this
        this.items.forEach(function(item, index){
          item.active = false
          if (Array.isArray(item.children) ) {
            item.children.forEach(function(child){
              if("/"+child.ruta === _this2.$route.path){
                item.active = true
              }
            })
          }
          Object.assign(_this2.items[index], item)
        })
      }else{
        this.drawerChild = false
      }
    },
  },
  watch: {
    drawer (value) {
        this.drawerChild = value;
    },
    drawerChild (value) {
        this.$emit('drawerStatus', value)
    }
  }
};
</script>
<style>
.v-navigation-drawer__content {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  overflow: hidden !important;
}
.drawer-menu--scroll{
  height: calc(100vh - 48px);
  overflow: auto;
}
</style>

