  <template>
    <div>
      <v-card class="v-cardp">
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            <template>Reportes Trabajos de Grado</template>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="4">
                <v-hover v-slot:default="{ hover }">
                  <v-card color="info" @click="dialogGeneral=true" dark
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }">
                    <v-card-text>
                      <v-container>
                        <v-row justify="center" align="center">
                          <v-col cols="12" justify="center" align="center">
                              <v-icon dark x-large>fa-file-alt</v-icon>
                          </v-col>
                          <v-col cols="12" justify="center" align="center">
                            <p class="body-1 text-center font-weight-bold">Reporte General</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-hover v-slot:default="{ hover }">
                  <v-card color="orange lighten-1" @click="dialogDashboard=true" dark
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }">
                    <v-card-text>
                      <v-container>
                        <v-row justify="center" align="center">
                          <v-col cols="12" justify="center" align="center">
                              <v-icon dark x-large>fa-table</v-icon>
                          </v-col>
                          <v-col cols="12" justify="center" align="center">
                            <p class="body-1 text-center font-weight-bold">Cuadro de Mando</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-hover v-slot:default="{ hover }">
                  <v-card color="cyan darken-4" @click="dialogDate=true" dark
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }">
                    <v-card-text>
                      <v-container>
                        <v-row justify="center" align="center">
                          <v-col cols="12" justify="center" align="center">
                              <v-icon dark x-large>fa-calendar-check</v-icon>
                          </v-col>
                          <v-col cols="12" justify="center" align="center">
                            <p class="body-1 text-center font-weight-bold">Acta de Reunión</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <DegreeWorkGeneral :dialog="dialogGeneral" @close="closeGeneral" ></DegreeWorkGeneral>
      <DegreeWorkDashboard :dialog="dialogDashboard" @close="closeDashboard" ></DegreeWorkDashboard>
      <DegreeWorkDate :dialog="dialogDate" @close="closeDate" ></DegreeWorkDate>
    </div>
</template>
<script>
  import DegreeWorkGeneral from './reports/DegreeWorkGeneral';
  import DegreeWorkDate from './reports/DegreeWorkDate';
  import DegreeWorkDashboard from './reports/DegreeWorkDashboard';
  export default{
    name: 'DegreeReport',
    components: { DegreeWorkGeneral, DegreeWorkDashboard, DegreeWorkDate },
    data() {
      return {
        dialogGeneral: false,
        dialogDashboard: false,
        dialogLoan: false,
        dialogDate: false,
      }
    },

    computed: {
    },

    watch: {
    },

    mounted () {
    },
    methods: {
      closeGeneral(){
        this.dialogGeneral = false
      },
      closeDashboard(){
        this.dialogDashboard = false
      },
      closeDate(){
        this.dialogDate = false
      },
    }
  }
</script>
<style scoped>
.v-cardp {
  transition: none !important;
  opacity: 1 !important;
}
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.8;
}

</style>
