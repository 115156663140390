  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        sort-by="title"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Facultades</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="pais">
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.name" label="* Nombre"
                          :rules="rules.name"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editItem(item)" v-on="on" class="mr-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  export default{
    name: 'Faculty',
    data() {
      return {
        dialog: false,
        search: '',
        rules: {
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 5 || 'Mínimo 5 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
        },
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nombre', value: 'name' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {id: 0, name: '', active: true},
        defaultItem: {id: 0, name: '', active: true},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.$http.get(URL_APP+'/api/faculties')
        .then(response => {
          this.desserts = response.data;
          this.$root.$loadApp(false)
        })
        .catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener las facultades",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        var url = URL_APP+"/api/faculties/" + item.id;
        this.$root.$confirm('Eliminar Facultad', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.$http.delete(url)
            .then(response => {
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
            })
            .catch(error => {
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:2000})
            });
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        this.$refs.pais.validate()
        if (this.valid){
          var method1 = "POST"
          if (this.editedIndex > -1) {
            //Editar Item
            method1 = "PUT"
          }
          this.$http({
            method: method1,
            url: URL_APP+'/api/faculties',
            data: this.editedItem
          })
          .then(response => {
            this.close()
            this.$root.$loadApp(false)
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$loadApp(false)
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },
    }
  }
</script>
