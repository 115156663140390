  <template>
    <div>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="template.moments"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Momentos</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="form2">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" >
                          <v-autocomplete
                            v-model="editedItem.id" 
                            item-value="id"
                            item-text="name"
                            :rules="rules.id"
                            :items="moments"
                            label="* Momento"
                            placeholder="Seleccione el Momento"
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field type="number"  
                          label="Mínimo" v-model="editedItem.pivot.minimun" ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field type="number"  
                          label="Máximo" v-model="editedItem.pivot.maximun" ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom v-if="currentUser.email != item.email">
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.pivot.order>1">
            <template v-slot:activator="{ on }">
              <v-icon @click="upItem(item)" v-on="on" class="mr-1" color="primary">fa-angle-up</v-icon>
            </template>
            <span>Subir</span>
          </v-tooltip>
          <v-tooltip bottom v-if="template.moments.indexOf(item) < (template.moments.length-1) && item.pivot.order">
            <template v-slot:activator="{ on }">
              <v-icon @click="downItem(item)" v-on="on" class="mr-1" color="primary">fa-angle-down</v-icon>
            </template>
            <span>Bajar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
</template>
<script>
  export default{
    name: 'MomentTemplate',
    props: {
      template: {
        type: Object,
        default: null
      },
      moments: {
        type: Array,
        default: null
      },
    },
    data() {
      return {
        dialog: false,
        valid: null,
        rules: {
          id: [
            v => !!v || 'Campo Requerido',
          ],
        },
        headers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Mínimo', value: 'pivot.minimun' },
          { text: 'Máximo', value: 'pivot.maximun' },
          { text: 'Orden', value: 'pivot.order' },
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        editedIndex: -1,
        editedItem: {id: null, pivot: {order:null, minimun: null, maximun:null} },
        defaultItem: {id: null, pivot: {order:null, minimun: null, maximun:null} },
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    methods: {

      deleteItem (item) {
        this.$root.$confirm('Eliminar Momento', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            const index = this.template.moments.indexOf(item)
            this.template.moments.splice(index, 1)
            this.reorderItems()
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.pivot = Object.assign({}, this.defaultItem.pivot)
          this.editedIndex = -1
        }, 300)
      },

      upItem(item){
        //Calcula el indice del Item
        var index = this.template.moments.indexOf(item)
        //Guarda el Item Anterior en otra variable
        var before = Object.assign({}, this.template.moments[index - 1])
        item.pivot.order = item.pivot.order - 1
        before.pivot.order = item.pivot.order + 1
        //Reemplaza el Item Anterior
        Object.assign(this.template.moments[index - 1], item)
        //Reemplaza el Item con el Anterior
        Object.assign(this.template.moments[index], before)
      },

      downItem(item){
        //Calcula el indice del Item
        var index = this.template.moments.indexOf(item)
        //Guarda el Item Siguiente en otra variable
        var after = Object.assign({}, this.template.moments[index + 1])
        item.pivot.order = item.pivot.order + 1
        after.pivot.order = item.pivot.order - 1
        //Reemplaza el Item Anterior
        Object.assign(this.template.moments[index + 1], item)
        //Reemplaza el Item con el Anterior
        Object.assign(this.template.moments[index], after)
      },

      async reorderItems(){
        var order = 1
        var _this2 = this
        if (Array.isArray(this.template.moments)){
          await this.template.moments.forEach(function(choice, index){
            if(choice.pivot.order){
              choice.pivot.order = order
              Object.assign(_this2.template.moments[index], choice)
              order ++
            }
          })
          this.orderMoments()
        }
      },

      orderMoments(){
        this.template.moments.sort(function (a, b) {
          return a.pivot.order - b.pivot.order
        });
      },

      async save () {
        this.$refs.form2.validate()
        var _this2 = this
        //Revisar si ya existe
        if (Array.isArray(this.template.moments)) {
          var index = await this.template.moments.find( (element) => element.id == _this2.editedItem.id )
          if(index > -1){
            this.valid = false
            _this2.$root.$alert({text:"Momento ya Existe",color:"error",timeout:2000})
          }
        }
        if(this.editedItem.pivot.minimun>0){
          console.log(!this.editedItem.pivot.maximun)
          console.log(parseInt(this.editedItem.pivot.maximun)<parseInt(this.editedItem.pivot.minimun))
          if(!this.editedItem.pivot.maximun || parseInt(this.editedItem.pivot.maximun)<parseInt(this.editedItem.pivot.minimun)){
            this.valid = false
            this.$root.$alert({text:"Error en Valor Máximo",color:"error",timeout:2000})
          }
        }else if(this.editedItem.pivot.maximun>0){
          if(!this.editedItem.pivot.minimun || parseInt(this.editedItem.pivot.maximun)<parseInt(this.editedItem.pivot.minimun)){
            this.valid = false
            this.$root.$alert({text:"Error en Valor Mínimo",color:"error",timeout:2000})
          }
          console.log("Mínimo: "+this.editedItem.pivot.minimun+" Máximo: "+this.editedItem.pivot.maximun)
        }
        if(this.valid){
          var order = 0
          var moment = await this.moments.find( (element) => element.id == _this2.editedItem.id )
          this.editedItem.name = moment.name
          if(!(this.editedItem.pivot.minimun || this.editedItem.pivot.maximun))
            this.editedItem.pivot.order = order + 1
          this.template.moments.push(this.editedItem)
          this.reorderItems()
          this.close()
        }
      },
    }
  }
</script>
