<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" scrollable persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            Cuadro de Mando Trabajos de Grado
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col cols="4" align="center">
                <span class="font-weight-bold">Programa</span>
              </v-col>
              <v-col cols="8" align="center">
                <v-autocomplete
                  v-model="report.program"
                  item-text="name"
                  item-value="id"
                  :items="programs"
                  chips
                  label="Programa(s)"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" align="center">
                <span class="font-weight-bold">Director</span>
              </v-col>
              <v-col cols="8" align="center">
                <v-autocomplete
                  v-model="report.director"
                  item-text="third.name"
                  item-value="third.id"
                  :items="thirds"
                  chips
                  label="Director(es)"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" align="center">
                <span class="font-weight-bold">Modalidad</span>
              </v-col>
              <v-col cols="8" align="center">
                <v-autocomplete
                  v-model="report.modality"
                  item-text="name"
                  item-value="id"
                  :items="modalities"
                  chips
                  label="Modalidad(es)"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="1500px" scrollable persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            Cuadro de Mando Trabajos de Grado
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="mx-3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="excel()">
                  <v-icon>fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Excel</span>
            </v-tooltip>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" icon small v-on="on" @click.stop="dialog2=false">
                 <v-icon dark>fa-times</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <template v-for="(template, keyt) in templates">
            <v-data-table v-if="template.items.length>0"
              :headers="template.headers"
              :items="template.items"
              :items-per-page="5"
              :key="'datatable'+keyt"
              class="mt-3"
            >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>{{ template['name']}}</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:[`item.moment${keyh}`]="{ item }" v-for="keyh in (template.headers.length - 3)">
              <v-chip v-if="item[`moment${keyh}`].length<1" color="red" dark :key="'chip'+keyh">Pendiente</v-chip>            
              <template v-else>
                <v-chip v-for="(moment, i) in item[`moment${keyh}`]" :key="i+'moment'+keyh" @dblclick="getAttacheds(null,moment)" color="green" dark>{{ moment.created_at }}</v-chip>
              </template>
            </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { URL_APP } from "../../../util/constants";
  import { createLinkFile } from '../../../util/functions';
  export default{
    name: 'DegreeWorkGeneral',
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        programs:[],
        thirds:[],
        modalities:[],
        report:{program:[], director:[], modality:[], type:''},
        dialog2: false,
        templates:[],
      }
    },

    computed: {
    },

    watch: {
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.getPrograms();
        this.getThirds();
        this.getModalities();
      },

      getPrograms () {
        this.$http.get(URL_APP+'/api/sirog/selprogramsdw')
        .then(response => {
          this.programs = response.data;
        })
        .catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener los Programas",color:"error",timeout:2000})
        });
      },

      getThirds() {
        var type_third = "Director"
        this.$http.get(URL_APP+"/api/sirog/degree_thirds", {params:{"type_third":type_third}} )
        .then(response => {
          this.thirds = response.data
        }).catch(error => {
          this.$root.$alert({text:error.response.data.message,color:"error",timeout:3000})
        })
      },

      getModalities() {
        this.$http.get(URL_APP+'/api/sirog/modalities', {params: {"type": "Virtual"}})
        .then(response => {
          this.modalities = response.data;
        })
        .catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener las Modalidades",color:"error",timeout:2000})
        });
      },

      close () {
        this.$emit('close')
      },

      getAttacheds(degree,item) {
        if(degree == null && item==null){
          this.$root.$loadApp(false)
        }else{
          var report = {degree:degree, moment:item.id}
          console.log(report)
          var url = URL_APP+"/api/sirog/getattached/" + JSON.stringify(report);
          this.$root.$loadApp(true)
          this.$http.get(url, {responseType: 'blob'}).then(response => {
            createLinkFile(response)
            //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
            this.$root.$loadApp(false)
          }).catch(error => { console.error(error)
            this.$root.$alert({text:"Error al Obtener los archivos",color:"error",timeout:2000})
            this.$root.$loadApp(false)
          });
        }
      },

      save () {
        this.report.type = "plano"
        var url = URL_APP+"/api/sirog/dashboard/" + JSON.stringify(this.report);
        this.$root.$loadApp(true)
        this.$http.get(url).then(response => {
          this.templates = response.data
          this.dialog2 = true
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener el Reporte",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      excel () {
        this.report.type = "excel"
        var url = URL_APP+"/api/sirog/dashboard/" + JSON.stringify(this.report);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener el Reporte",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },
    }
  }
</script>
