<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            <template>{{ moment.name }}
            </template>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <v-form v-model="valid" ref="moment">
            <v-container fluid>
              <v-row>
                <v-col cols="12" >
                  <b>Proyecto:</b> {{ editedItem.title }}
                </v-col>
                <template v-if="moment.director">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedMoment.director" 
                      item-value="third.id"
                      item-text="third.name"
                      :rules="rules.director"
                      :items="thirds"
                      label="* Director"
                      placeholder="Seleccione el Director del Trabajo de Grado"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedMoment.codirector" 
                      item-value="third.id"
                      item-text="third.name"
                      :rules="rules.codirector"
                      :items="thirds"
                      label="Codirector"
                      placeholder="Seleccione el Codirector del Trabajo de Grado"
                      clearable
                    ></v-autocomplete>
                  </v-col>   
                </template>
                <template v-if="moment.panel">
                  <v-col cols="12">
                    <v-select
                      :items="panels"
                      v-model="editedMoment.panel" 
                      label="* Panel Evaluador"
                      placeholder="Seleccione el Panel Evaluador"
                      required
                      @change="changePanel(editedMoment.panel)"
                      :rules="rules.panel"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table dense :headers="headers" hide-default-footer :items-per-page="100"
                      :items="thirds2" item-key="third.email" class="elevation-1"
                    ></v-data-table>
                  </v-col>
                </template>
                <template v-if="moment.evaluator">
                  <v-col cols="12">
                    <Evaluator :moment="editedMoment" :thirds="thirds"></Evaluator>
                  </v-col>
                </template>
                <template v-if="moment.schedule">
                  <v-col cols="6">
                    <v-dialog
                      ref="dialogS"
                      v-model="dialogDate"
                      :return-value.sync="editedMoment.date_schedule"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedMoment.date_schedule"
                          label="Fecha Sustentación"
                          prepend-icon="fa-calendar-alt"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedMoment.date_schedule" scrollable :min="fechaHoy()">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dialogDate = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialogS.save(editedMoment.date_schedule)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6">
                    <v-dialog
                      ref="dialogH"
                      v-model="dialogTime"
                      :return-value.sync="editedMoment.time_schedule"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedMoment.time_schedule"
                          label="Hora de Sustentación"
                          prepend-icon="fa-clock"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="editedMoment.time_schedule"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dialogTime = false">Cancelar</v-btn>
                        <v-btn text color="primary" @click="$refs.dialogH.save(editedMoment.time_schedule)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </template>
                <v-col cols="12" v-if="moment.attached>0">
                  <v-file-input multiple 
                    v-model="editedMoment.files"
                    show-size counter
                    label="Seleccione Archivo(s)"
                    clearable
                    required
                    small-chips
                    accept=".pdf,.doc,.docx"></v-file-input>
                </v-col>
                <v-col cols="12" v-if="moment.end">
                  <v-text-field
                    v-model="editedMoment.score"
                    required
                    label="Nota Final"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="moment.state">
                  <v-select
                    v-model="editedMoment.state"
                    :items="states"
                    :rules="rules.state"
                    required
                    @change="getStudents"
                    label="Escoja una Acción"
                  ></v-select>
                </v-col>
                <v-col cols="12" v-if="editedMoment.state=='Retirar'">
                  <v-data-table
                    v-model="editedMoment.students"
                    :headers="headers2"
                    :items="students"
                    item-key="pivot.third_id"
                    show-select
                    dense
                    hide-default-footer
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-col>
                <v-col cols="12" v-if="moment.change_name">
                  <v-text-field
                    v-model="editedMoment.title"
                    :rules="rules.title"
                    required
                    label="Nuevo Titulo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="moment.observation">
                  <v-textarea
                    v-model="editedMoment.observation"
                    :value="editedMoment.observation"
                    :rules="rules.observation"
                    required
                    label="Observación"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  import { objectToFormData } from '../../util/functions';
  import Evaluator from './Evaluator';
  export default{
    name: 'MyDegreeWork',
    components: {Evaluator},
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      editedItem: {
        type: Object,
        default: null
      },
      moment: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        search: '',
        dialogDate: false,
        dialogTime: false,
        rules: {
          panel: [
            v => !!v || 'Campo requerido',
          ],
          director: [
            v => !!v || 'Campo requerido',
          ],
          modality_id: [
            v => !!v || 'Campo Requerido',
          ],
          observation: [
            v => !!v || 'Campo Requerido',
          ],
          title: [
            v => !!v || 'Campo requerido',
            v => v.length >= 3 || 'Mínimo 3 caracteres',
            v => v.length < 300 || 'Máximo 300 caracteres',
          ],
          state: [
            v => !!v || 'Campo Requerido',
          ],
          files: [
            v => !v || v.length < 1 || 'Debe Seleccionar al menos un archivo!',
            v => !v || v.size < 2000000 || 'Archivo debe pesar menos de 2 MB!',
            v => v.size > 1 || 'Archivo no puede estar vacío!',
          ],
        },
        headers: [
          { text: 'Nombre', value: 'third.name' },
          { text: 'Email', value: 'third.email' },
          { text: 'Teléfono', value: 'third.mobile' },
        ],
        headers2: [
          { text: 'Identificacion', value: 'identification' },
          { text: 'Nombre', value: 'name' },
        ],
        thirds:[],
        panels:[],
        thirds2:[],
        students:[],
        states:["Rechazar","Anular","Retirar"],
        editedIndex: -1,
        editedMoment: {id: 0, date_schedule:null, time_schedule:null, director:null, codirector:null, files:[] , moment_id:0, degree_work_id:0, evaluators:[], students:[], score:null, state:null},
        defaultMoment: {id: 0, date_schedule:null, time_schedule:null, director:null, codirector:null, files:[] , moment_id:0, degree_work_id:0, evaluators:[], students:[], score:null, state:null},
        valid: true,
      }
    },

    computed: {
      currentUser(){
        return this.$store.getters.currentUser
      },
    },

    mounted () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.editedMoment = Object.assign({}, this.defaultMoment)
        if(this.moment.director){
          this.getDirectors()
        }
        if(this.moment.evaluator || this.moment.panel){
          this.getEvaluators()
        }
      },
      
      getDirectors () {
        this.getThirds("Director");
      },

      getEvaluators () {
        this.getThirds("Evaluador");
      },

      getStudents () {
        var _this2 = this
        this.students=[]
        this.editedItem.thirds.forEach(function(third){
          if(third.pivot.type_third=="Estudiante" && third.pivot.active){
          _this2.students.push(third)
          }
        })
        return _this2.students
      },

      getThirds(type_third) {
        console.log("Obteniendo Terceros")
        var _this2 = this
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.$http.get(URL_APP+"/api/sirog/degree_thirds", {params:{"type_third":type_third}} )
        .then(response => {
          this.thirds = response.data
          console.log(this.thirds)
          this.$root.$loadApp(false)
          if(this.moment.panel){
            if (Array.isArray(this.thirds) ) {
              //Añadir paneles
              this.thirds.forEach(function(third){
                var bandera = false
                _this2.panels.forEach(function(panel){
                  if(panel==third.panel){
                    bandera = true
                  }
                })
                if(!bandera){
                  _this2.panels.push(third.panel)
                }
              })
            }
          }
        }).catch(error => {
          this.$root.$alert({text:error.response.data.message,color:"error",timeout:3000})
          this.$root.$loadApp(false)
        })
      },
      
      fechaHoy(){
        return new Date().toISOString().substr(0, 10)
      },

      changePanel(panel){
        this.thirds2 = Array()
        var _this2 = this
        if (Array.isArray(this.thirds) ) {
          //Añadir paneles
          this.thirds.forEach(function(third){
            if(panel==third.panel){
              _this2.thirds2.push(third)
            }
          })
        }
      },

      close(){
        this.editedMoment = Object.assign({}, this.defaultMoment)
        this.$emit('close')
      },

      save () {
        this.$refs.moment.validate()
        var error = "Revise los Errores"
        if(this.editedMoment.director!=null){
          if(this.editedMoment.codirector==this.editedMoment.director){
            this.valid = false
            error = "Codirector y director no pueden ser iguales"
          }
        }
        if(this.moment.attached>0){
          //console.log(this.editedMoment.files.length)
          if(this.editedMoment.files.length<this.moment.attached || this.editedMoment.files.length>this.moment.attached){
            this.valid = false
            error = "Por favor Seleccione ("+this.moment.attached+") archivo"
          }else{
            this.valid = true
            //console.log(this.editedMoment.files.length)
            if(this.moment.end){
              if(this.editedMoment.score==null || this.editedMoment.score==""){
                this.valid = false
                error = "Nota final obligatoria"
              }else{
                this.valid = true
              }
            }
          }
        }
        if(this.moment.schedule){
          if(this.editedMoment.date_schedule==null || this.editedMoment.time_schedule==null){
            this.valid = false
            error = "Por favor Revise los campos obligatorios"
          }else{
            this.valid = true
          }
        }
        if(this.moment.evaluator){
          if(this.editedMoment.evaluators.length<2 || this.editedMoment.evaluators.length>2){
            this.valid = false
            error = "Deben ser 2 Evaluadores"
          }else{
            this.valid = true
          }
        }
        if (this.valid){
          this.$root.$loadApp(true)
          this.editedMoment.moment_id = this.moment.id
          this.editedMoment.degree_work_id = this.editedItem.id
          const formData = objectToFormData(this.editedMoment)
          var method1 = "post"
          this.$http({
            method: method1,
            url: URL_APP+'/api/sirog/dwmoments',
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            this.close()
            this.$root.$loadApp(false)
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$loadApp(false)
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
        }else{
          this.$root.$alert({text:error,color:"error",timeout:2000})
        }
      },
    }
  }
</script>
