import Welcome from '../views/Welcome';
import Login from '../views/auth/Login';
import Recover from '../views/auth/Recover';
import FindRecover from '../views/auth/FindRecover';
//Configuración
import Profile from '../views/config/Profiles';
import User from '../views/config/Users';
//Tablas Básicas
import Sectional from '../views/general/Sectional';
import Division from '../views/general/Division';
import Faculty from '../views/general/Faculty';
import Program from '../views/general/Program';
import Modality from '../views/dw/Modality';
//Trabajos de Grado
import MyDegreeWork from '../views/dw/MyDegreeWork';
import DegreeWork from '../views/dw/DegreeWork';
import ThirdDegreeWork from '../views/dw/ThirdDegreeWork';
import ReportDegreeWork from '../views/dw/ReportDegreeWork';
import Template from '../views/dw/Template';
import Alert from '../views/dw/Alert';
import UploadDate from '../views/dw/UploadDate';
//Dashboard
import Dashboard from '../views/dashboard/Dashboard';

export const routes = [
		{	path: '/welcome', name: 'welcome', component: Welcome, meta: {	title: 'Bienvenido' }},
		{ path: '/login', name: 'login', component: Login, meta: {	title: 'Iniciar Sesión' }},
		{	path: '/recover',	name: 'recover', component: Recover, meta: {	title: 'Recuperar Contraseña' }},
		{ path: '/findrecover/:t', name: 'findrecover',	component: FindRecover, meta: {	title: 'Recuperar Contraseña' }},
		{	path: '/profiles', name: 'profiles', component: Profile, meta: { requiresAuth: true, title: 'Perfiles'	}	},
		{	path: '/users',	name: 'users', component: User, meta: {	requiresAuth: true, title: 'Usuarios' } },
		{ path: '/sectionals', name: 'sectionals', component: Sectional, meta: {	requiresAuth: true, title: 'Seccionales' } },
		{ path: '/divisions', name: 'divisions', component: Division, meta: {	requiresAuth: true, title: 'Divisiones' } },
		{ path: '/faculties', name: 'faculties', component: Faculty, meta: {	requiresAuth: true, title: 'Facultades' } },
		{ path: '/programs', name: 'programs', component: Program, meta: {	requiresAuth: true, title: 'Programas' } },
		{ path: '/modalities', name: 'modalities', component: Modality, meta: {	requiresAuth: true, title: 'Modalidades' } },
		//Trabajos de Grado
		{	path: '/mydegreeworks',	name: 'mydegreework', component: MyDegreeWork, meta: {	requiresAuth: true, title: 'Mis Opciones de Grado' } },
		{	path: '/degreeworks',	name: 'degreeworks', component: DegreeWork, meta: {	requiresAuth: true, title: 'Opciones de Grado' } },
		{	path: '/third_degreework',	name: 'third_degreework', component: ThirdDegreeWork, meta: {	requiresAuth: true, title: 'Terceros' } },
		{	path: '/reportdw',	name: 'report_degreework', component: ReportDegreeWork, meta: {	requiresAuth: true, title: 'Reportes' } },
		{ path: '/templates', name: 'templates', component: Template, meta: {	requiresAuth: true, title: 'Plantillas' }  },
		{ path: '/alerts', name: 'alerts', component: Alert, meta: {	requiresAuth: true, title: 'Alertas' }  },
		{ path: '/datesdw', name: 'datesdw', component: UploadDate, meta: {	requiresAuth: true, title: 'Cargar Fechas' }  },
		{ path: '/dashboard', name: 'dashboard', component: Dashboard, meta: {	requiresAuth: true, title: 'Tablero de Control' }  },
	];