var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.template.moments},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Momentos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-plus")])],1)]}}])},[_c('span',[_vm._v("Nuevo")])]),_c('v-dialog',{attrs:{"max-width":"800px","scrollable":"","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form2",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"item-value":"id","item-text":"name","rules":_vm.rules.id,"items":_vm.moments,"label":"* Momento","placeholder":"Seleccione el Momento","required":""},model:{value:(_vm.editedItem.id),callback:function ($$v) {_vm.$set(_vm.editedItem, "id", $$v)},expression:"editedItem.id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":"Mínimo"},model:{value:(_vm.editedItem.pivot.minimun),callback:function ($$v) {_vm.$set(_vm.editedItem.pivot, "minimun", $$v)},expression:"editedItem.pivot.minimun"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":"Máximo"},model:{value:(_vm.editedItem.pivot.maximun),callback:function ($$v) {_vm.$set(_vm.editedItem.pivot, "maximun", $$v)},expression:"editedItem.pivot.maximun"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary darken-1","outlined":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary darken-1","outlined":""},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.viewMode))])],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.currentUser.email != item.email)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v("fa-trash")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e(),(item.pivot.order>1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.upItem(item)}}},on),[_vm._v("fa-angle-up")])]}}],null,true)},[_c('span',[_vm._v("Subir")])]):_vm._e(),(_vm.template.moments.indexOf(item) < (_vm.template.moments.length-1) && item.pivot.order)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.downItem(item)}}},on),[_vm._v("fa-angle-down")])]}}],null,true)},[_c('span',[_vm._v("Bajar")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }