  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        sort-by="title"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Alertas de Opción de Grado</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="form">
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.subject" label="* Asunto"
                          :rules="rules.subject"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <quill-editor
                            ref="myQuillEditor"
                            v-model="editedItem.message"
                            :options="editorOption"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="editedItem.days" label="* Días antes de expiración"
                          :rules="rules.days"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="editedItem.color" label="* Color"
                          :rules="rules.color" hint="Hexagecimal o Nombre del Color"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.sirog_template_id" 
                            item-value="id"
                            item-text="name"
                            :rules="rules.template_id"
                            :items="templates"
                            label="* Plantilla"
                            placeholder="Seleccione la Plantilla"
                            required hint="Plantilla modelo Opción de Grado"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete clearable
                            v-model="editedItem.moment_id" 
                            item-value="id"
                            item-text="name"
                            :rules="rules.moment_id"
                            :items="moments"
                            label="Momento Mínimo"
                            placeholder="Seleccione el Momento"
                            required hint="Momento mínimo que debe cumplir antes que emita la alerta"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editItem(item)" v-on="on" class="mr-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
</template>
<script>
import { URL_APP } from "../../util/constants";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

  export default{
    name: 'Alert',
    components: {
      'quill-editor':quillEditor
    },
    data() {
      return {
        dialog: false,
        editorOption: {
          // Some Quill options...
        },
        search: '',
        rules: {
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 5 || 'Mínimo 5 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
        },
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Asunto', value: 'subject' },
            { text: 'Color', value: 'color' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
        desserts: [],
        templates: [],
        moments:[],
        editedIndex: -1,
        editedItem: {id: 0, subject: '', message:null, days:0, color:null, moment_id:null, template_id:null, active: true},
        defaultItem: {id: 0, subject: '', message:null, days:0, color:null, moment_id:null, template_id:null, active: true},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      async 'editedItem.sirog_template_id'(val){
        console.log(val)
        if(val!=null){
          var template = await this.templates.find( (el) => el.id == val )
          console.log(template)
          this.moments = template.moments
        }else{
          this.moments = []
        }
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.$http.get(URL_APP+'/api/sirog/alerts')
        .then(response => {
          this.desserts = response.data;
          this.$root.$loadApp(false)
        })
        .catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener las alertas",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
        this.getTemplates()
      },

      async getTemplates(){
        var req = await this.$http.get(URL_APP+'/api/sirog/seltemplates')
        this.templates = req.data
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        var url = URL_APP+"/api/sirog/alerts/" + item.id;
        this.$root.$confirm('Eliminar Alerta', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.$http.delete(url)
            .then(response => {
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
            })
            .catch(error => {
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:2000})
            });
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        this.$refs.form.validate()
        if (this.valid){
          var method1 = "POST"
          if (this.editedIndex > -1) {
            //Editar Item
            method1 = "PUT"
          }
          this.$http({
            method: method1,
            url: URL_APP+'/api/sirog/alerts',
            data: this.editedItem
          })
          .then(response => {
            this.close()
            this.$root.$loadApp(false)
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$loadApp(false)
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },
    }
  }
</script>
