  <template>
    <div>
      <v-data-iterator
        :items="items"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
      >

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.created_at"
              cols="12"
              sm="6"
              md="4"
            >
              <v-card :color="item.state=='Finalizado'?'green':item.last_moment.color">
                <v-card-title class="body-1 font-weight-bold white--text" >{{ item.title }}
                  <v-spacer></v-spacer>
                  <v-icon dark @click="helpItem(item)">fa-question</v-icon>
                </v-card-title>
                <v-card-text class="white">
                  <v-row>
                    <v-col cols="5" md="4" class="my-0 py-0 font-weight-black" :class="`${item.state=='Finalizado'?'green':item.last_moment.color}--text`">Estado:</v-col>
                    <v-col cols="7" md="8" class="my-0 py-0" :class="`${item.state=='Finalizado'?'green':item.last_moment.color}--text`">{{ item.state=='Finalizado'?'Finalizado':item.last_moment.name }} <span v-if="item.score"> - {{item.score}}</span></v-col>
                    <v-col cols="5" md="4" class="my-0 py-0 font-weight-black" :class="{ 'blue--text': sortBy === 'created_at' }">Fecha:</v-col>
                    <v-col cols="7" md="8" class="my-0 py-0" :class="{ 'blue--text': sortBy === 'created_at' }">{{ item.created_at }}</v-col>
                    <v-col cols="5" md="4" class="my-0 py-0 font-weight-black" :class="{ 'blue--text': sortBy === 'program' }">Programa:</v-col>
                    <v-col cols="7" md="8" class="my-0 py-0" :class="{ 'blue--text': sortBy === 'program' }">{{ item.program.name }}</v-col>
                    <v-col cols="5" md="4" class="my-0 py-0 font-weight-black" :class="{ 'blue--text': sortBy === 'modality' }">Modalidad:</v-col>
                    <v-col cols="7" md="8" class="my-0 py-0" :class="{ 'blue--text': sortBy === 'modality' }">{{ item.modality.name }}</v-col>
                    <v-col cols="5" md="4" class="my-0 py-0 font-weight-black" :class="{ 'blue--text': sortBy === 'deadline' }" v-if="item.deadline">Fecha Entrega:</v-col>
                    <v-col cols="7" md="8" class="my-0 py-0" :class="{ 'blue--text': sortBy === 'modality' }" v-if="item.deadline">{{ item.deadline }}</v-col>
                    <v-col cols="5" md="4" class="my-0 py-0 font-weight-black" >Miembros:</v-col>
                    <v-col cols="7" md="8" class="my-0 py-0">
                      <ul>
                        <template v-for="third in item.thirds"><li :key="third.name" v-if="third.pivot.type_third=='Estudiante'">{{ third.name }}</li></template>
                      </ul>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-row align="center" justify="center">
                    <v-col cols="12" class="my-0 py-0 text-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn dark class="mx-2 my-2" text icon small v-on="on" @click.stop="historyItem(item)">
                            <v-icon>fa-history</v-icon>
                          </v-btn>
                        </template>
                        <span>Historial</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn dark class="mx-2 my-2" text icon small v-on="on" @click.stop="getAttacheds(item.id,null)">
                            <v-icon>fa-cloud-download-alt</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar Archivos</span>
                      </v-tooltip>
                      <template v-if="item.next_moment">
                        <!--<v-tooltip bottom v-if="item.next_moment.solution">-->
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on }">
                            <v-btn dark class="mx-2 my-2" text icon small v-on="on" @click.stop="newMoment(item, item.next_moment)">
                              <v-icon>{{ item.next_moment.icon }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ item.next_moment.name }}</span>
                        </v-tooltip>
                      </template>
                      <template v-for="(moment, i) in item.other_moments">
                        <v-tooltip bottom :key="i">
                          <template v-slot:activator="{ on }">
                            <v-btn dark class="mx-2 my-2" text icon small v-on="on" @click.stop="newMoment(item, moment)">
                              <v-icon>{{ moment.icon }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ moment.name }}</span>
                        </v-tooltip>
                      </template>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <!--Ayuda-->
      <Help :dialog="dialogH2" @close="close" ref="help" :moments="itemMoments"></Help>
    </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  import Help from './Helpgpg';
  import { createLinkFile } from '../../util/functions';
  export default {
    name:'DataIterator',
    components:{ Help },
    props: {
      items: {
        type: Array,
        default: null
      },
    },
    data () {
      return {
        itemsPerPageArray: [6, 9, 12],
        dialogH2: false,
        itemMoments: [],
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 6,
        sortBy: 'name',
        keys: [
          {name:'Titulo', key:'title'},
          {name:'Programa', key:'program'},
          {name:'Modalidad', key:'modality'},
          {name:'Integrantes', key:'members'},
        ],
      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key.key !== `title`)
      },
    },
    methods: {
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      helpItem(item){
        this.itemMoments = item.template.moments
        this.dialogH2 = true
        console.log(this.itemMoments)
      },
      close(){
        this.dialogH2 = false
      },
      historyItem (item) {
        this.$emit('historyItem',item)
      },
      newMoment(item, moment) {
        this.$emit('newMoment',item,moment)
      },
      getAttacheds(degree,moment) {
        var report = {degree:degree, moment:moment}
        var url = URL_APP+"/api/sirog/getattached/" + JSON.stringify(report);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener los archivos",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },
    },
  }
</script>