  <template>
    <div>
      <v-card min-height="400px">
        <v-toolbar
          dark dense
          color="primary"
          class="mb-1"
        >
        <v-spacer></v-spacer>
          <v-toolbar-title>
            Trabajos de Grado
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon dark @click="dialogH2=true">fa-question</v-icon>
          <v-spacer></v-spacer>
          <v-btn
            fab
            color="secondary"
            dark
            absolute
            bottom
            right
            @click="registerDegreeWork"
          >
            <v-icon>fa-cloud-upload-alt</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-3">
          En este espacio puede realizar el registro y seguimiento a los trabajos de Grado
          <v-container fluid>
            <DataIterator :items="items" @historyItem="historyItem"></DataIterator>
          </v-container>
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
        <v-card>
          <v-toolbar dark color="primary" dense>
            <v-toolbar-title>
              <template>{{ formTitle }}
              </template>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                  X
                </v-btn>
              </template>
              <span>Cerrar</span>
            </v-tooltip>
          </v-toolbar>

          <v-card-text>
            <v-form v-model="valid" ref="degreework" @submit.prevent="save">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedItem.program_id" 
                      item-value="id"
                      item-text="name"
                      :rules="rules.program_id"
                      :items="programs"
                      label="* Programa"
                      placeholder="Seleccione el Programa"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedItem.modality_id" 
                      item-value="id"
                      item-text="name"
                      :rules="rules.modality_id"
                      :items="modalities"
                      label="* Modalidad"
                      placeholder="Seleccione la Modalidad"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.title" label="* Titulo"
                    :rules="rules.title" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input multiple 
                      v-model="editedItem.files"
                      :rules="rules.files"
                      show-size counter
                      label="Seleccione Archivo(s)"
                      clearable
                      small-chips
                      accept=".pdf,.doc,.docx,.zip,.rar"></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <!--Estudiantes-->
            <Associated :degreeWork="editedItem"></Associated>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
            <v-btn color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Dialogo de Historial-->
      <HistoryDegreeWork :editedItem="editedItem" :dialog="dialogH" @close="close" @getAttacheds="getAttacheds"></HistoryDegreeWork>
      <!--Ayuda-->
      <Help :dialog="dialogH2" @close="closeH" ref="help"></Help>
    </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  import { objectToFormData, createLinkFile } from '../../util/functions';
  import Associated from './Associated';
  import HistoryDegreeWork from './HistoryDegreeWork';
  import DataIterator from './DataIterator';
  import Help from './Helpgpg';
  export default{
    name: 'MyDegreeWork',
    components:{ Associated, HistoryDegreeWork, DataIterator, Help },
    data() {
      return {
        dialog: false,
        dialogH: false,
        dialogH2: false,
        search: '',
        rules: {
          title: [
            v => !!v || 'Campo requerido',
            v => v.length >= 3 || 'Mínimo 3 caracteres',
            v => v.length < 300 || 'Máximo 300 caracteres',
          ],
          program_id: [
            v => !!v || 'Campo Requerido',
          ],
          files: [
            v => {
              if (v) {
                return true
              } else {
                return 'Campo requerido'
              }
            },
          ],
          modality_id: [
            v => !!v || 'Campo Requerido',
          ],
        },
        headers: [
          { text: 'Id', value: 'id' },
          { text: 'Titulo', value: 'title' },
          { text: 'Modalidad', value: 'modality' },
          { text: 'Programa', value: 'program' },
          { text: 'Fecha', value: 'date' },
          { text: 'Estado', value: 'state' },
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        items: [],
        programs: [],
        modalities:[],
        editedIndex: -1,
        editedItem: {id: null, title: '', modality_id: null, program_id:'', files: [], associated:[]},
        defaultItem: {id: null, title: '', modality_id: null, program_id:'', files: [], associated:[]},
        valid: null,
        itemsPerPageArray: [200, 400, 500],
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 200,
        sortBy: 'name',
        modality: null,
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Registrar Trabajo de Grado' : 'Ver / Editar Trabajo de Grado'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      },
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'editedItem.modality_id'(val){
        if(val!=null){
          this.modality = this.modalities.find( (el) => el.id == val )
          if(this.modality.register)
            this.editedItem.title = this.modality.name
        }
        else
          this.modality = null
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.$http.get(URL_APP+'/api/sirog/mydegreeworks')
        .then(response => {
          this.items = response.data;
          this.$root.$loadApp(false)
        })
        .catch(error => {
          this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
        this.getPrograms()
        this.getModalities()
      },
      
      registerDegreeWork(){
        this.$root.$loadApp(true)
        //Buscar el Estudiante Actual
        this.$http.get(URL_APP+'/api/sirog/verifyAssociated',{params:{"type":"user"}})
        .then(response => {
          this.$root.$loadApp(false)
          //Adicionar el Estudiante
          this.editedItem.associated.push(response.data)
          //Mostrar el Formulario
          this.dialog = true
        })
        .catch(error => {
          this.$root.$loadApp(false)
          this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
        });
      },

      getAttacheds(degree,moment) {
        var report = {degree:degree, moment:moment}
        var url = URL_APP+"/api/sirog/getattached/" + JSON.stringify(report);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener los archivos",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      getPrograms () {
        this.$http.get(URL_APP+'/api/sirog/programsdw')
        .then(response => {
          this.programs = response.data;
        })
        .catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener los Programas",color:"error",timeout:2000})
        });
      },

      getModalities() {
        this.$http.get(URL_APP+'/api/sirog/modalities', {params: {"type": "Virtual"}})
        .then(response => {
          this.modalities = response.data;
        })
        .catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener las Modalidades",color:"error",timeout:2000})
        });
      },

      editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      historyItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogH = true
      },

      close () {
        this.dialog = false
        this.dialogH = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.associated = Array();
          this.editedItem.files = Array();
          this.editedIndex = -1
        }, 300)
      },

      closeH(){
        this.dialogH2 = false
      },

      async save () {
        this.$refs.degreework.validate();
        var error = "Revise los Errores"
        if(this.valid){
          if(this.editedItem.associated.length<1){
            this.valid = false
            error = "Mínimo 1 estudiante"
          }else if(this.modality.register){
            if(this.editedItem.associated.length>1){
              this.valid = false
              error = "Máximo 1 estudiante"
            }
          }else{
            if(this.editedItem.associated.length>3){
              this.valid = false
              error = "Máximo 3 estudiantes"
            }
          }
        }
        if (this.valid){
          this.$root.$loadApp(true)
          const formData = objectToFormData(this.editedItem)
          var method1 = "post"
          if (this.editedIndex > -1) {
            //Editar Item
            method1 = "put"
          }
          this.$http({
            method: method1,
            url: URL_APP+'/api/sirog/degreeworks',
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            this.close()
            this.$root.$loadApp(false)
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$loadApp(false)
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
        }else{
          this.$root.$alert({text:error,color:"error",timeout:2000})
        }
      },

      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    }
  }
</script>
