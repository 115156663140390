<template>
  <div>
    <v-card color="white"
      elevation="20"
      :loading="loading"
    >
      <v-card-text>
        <canvas :id="id" width="768" height="384"></canvas>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
  export default{
    name: 'PieProjects',
    props: {
      title: String,
      id: String,
      loading: Boolean
    },
    data() {
      return {
        graph_object: null,
      }
    },
    methods: {
      initialize (chartData) {
        const ctx = document.getElementById(this.id);
        this.graph_object = new Chart(ctx, chartData);
      },
      update(newData){
        this.graph_object.data = newData.data;
        this.graph_object.options = newData.options;
        this.graph_object.type = newData.type;
        this.graph_object.update();
      }
    }
  }
</script>

<style>

</style>