var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"color":"primary","dark":"","app":"","clipped-left":_vm.$vuetify.breakpoint.mdAndUp}},[(_vm.currentUser)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({on:{"click":_vm.handleDrawerToggle}},on))]}}],null,false,830940686)},[_c('span',[_vm._v("Menu")])]):[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on3 = ref.on3;
return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"icon":"","to":"/"}},on3),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-university")])],1)]}}])},[_c('span',[_vm._v("Inicio")])])],_c('v-toolbar-title',[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Sistema Institucional de Registro Opción de Grado")]),_c('span',{staticClass:"hidden-md-and-up"},[_vm._v("SIROG")])]),_c('v-spacer'),(_vm.currentUser)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"icon":""},on:{"click":function($event){return _vm.handleFullScreen()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-expand")])],1)]}}],null,false,1415017313)},[_c('span',[_vm._v("Pantalla Completa")])]),_c('v-menu',{staticClass:"elevation-1",attrs:{"offset-y":"","origin":"center center","nudge-bottom":14,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[(false)?_c('v-avatar',{attrs:{"size":"30px"}},[_c('img',{attrs:{"src":"/static/avatar/man_4.jpg","alt":_vm.currentUser.name}})]):_c('v-icon',[_vm._v("fa-user-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.currentUser.name))])])]}}],null,false,963942481)},[_c('v-list',{attrs:{"flat":""}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":!item.href ? { name: item.name } : null,"href":item.href},on:{"click":item.click}},[_c('v-list-item-icon',[(item.icon)?_c('v-icon',[_vm._v(_vm._s(item.icon))]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","href":"http://www.ustabuca.edu.co","target":"_blank"}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("fa-globe")])],1)]}}])},[_c('span',[_vm._v("Página Web")])]),_c('div',{staticClass:"d-flex d-sm-none"},[_c('v-menu',{staticClass:"elevation-1",attrs:{"offset-y":"","origin":"center center","nudge-bottom":14,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("fab fa-hubspot")])],1)]}}],null,true)},[_c('span',[_vm._v("Redes Sociales")])])]}}])},[_c('v-list',{attrs:{"flat":""}},_vm._l((_vm.redes),function(red,i){return _c('v-list-item',{key:i,attrs:{"href":red.href,"target":"_blank"}},[_c('v-list-item-icon',[(red.icon)?_c('v-icon',[_vm._v(_vm._s(red.icon))]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(red.title)}})],1)],1)}),1)],1)],1),_c('div',{staticClass:"d-none d-sm-flex"},_vm._l((_vm.redes),function(red,i){return _c('v-tooltip',{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","href":red.href,"target":"_blank"}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v(_vm._s(red.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(red.title))])])}),1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":"/login"}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-user-alt")]),_c('span',{staticClass:"d-none d-sm-flex"},[_vm._v("Inicio Sesión")])],1)]}}])},[_c('span',[_vm._v("Iniciar Sesión")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }