export function initialize(store, router, axios) {
    console.log(store.getters.currentUser)
    axios = axios ? axios : window.getApp.$http
    if (store.getters.currentUser) {
        //Revisar si ya expiró el token
        var actual = new Date() / 1000 + 300;
        if (store.getters.currentUser.expire - actual <= 0){
            //Si ya expiró el token, redireccionelo al login
            store.commit('logout');
            router.push('/');
        }else{
            //Si no ha expirado asigne los valores normal
            setAuthorization(store.getters.currentUser.token.access_token, axios);
        }
        //console.log(store.getters.currentUser.token);
    }

    router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        const currentUser = store.state.currentUser;
    
        if(requiresAuth && !currentUser) {
            next('/');
        }else if(to.path == '/' && currentUser) {
            next('/dashboard');
        }else if(to.path == '/login' && currentUser) {
            next('/dashboard');
        }else if(to.path == '/signup' && currentUser) {
            next('/dashboard');
        }else if(to.path == '/activate' && currentUser) {
            next('/dashboard');
        }else if(to.path == '/recover' && currentUser) {
            next('/dashboard');
        }else {
            next();
        }
    });
    
    axios.interceptors.response.use(null, (error) => {
        if (error.response.status == 401) {
            store.commit('logout');
            router.push('/');
        }
        return Promise.reject(error);
    });
}

export function setAuthorization(token, axios) {
    axios = axios ? axios : window.getApp.$http
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}
