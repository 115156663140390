<template>
  <v-dialog v-model="dialog" max-width="500px" scrollable persistent>
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>
          <template>Flujo de Opción de Grado
          </template>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
              X
            </v-btn>
          </template>
          <span>Cerrar</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <template v-for="moment in moments">
          <v-card
            :color="moment.color"
            :key="moment.name"
            dark
            max-width="500"
            class="mt-2"
            outlined
          >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="moment.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="moment.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{moment.pivot.order}}
                </v-list-item-action>
              </v-list-item>
          </v-card>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'Help',
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      moments:{
        type: Array,
        default: null        
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
      },
      close(){
        this.$emit('close')
      },
    },
  }
</script>