function addZero(i) {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
}

//Obtiene la Fecha Actual
export function fechaHoy(){
  var hoy = new Date();
  var dd = addZero(hoy.getDate());
  var mm = addZero(hoy.getMonth()+1);
  var yyyy = hoy.getFullYear();
  return (yyyy+"-"+mm+"-"+dd);
}

//Cuenta las Palabras
export function countWords(texto){
	if (texto==="" || texto===null){
		return 0;
	}else{
		//Reemplazamos los saltos de linea por espacios
		texto = texto.replace (/\r?\n/g," ");
		//Reemplazamos los espacios seguidos por uno solo
		texto = texto.replace (/[ ]+/g," ");
		//Quitarmos los espacios del principio y del final
		texto = texto.replace (/^ /,"");
		texto = texto.replace (/ $/,"");
		//Troceamos el texto por los espacios
		var textoTroceado = texto.split(" ");
		//Contamos todos los trozos de cadenas que existen
		var numeroPalabras = textoTroceado.length;
		//Mostramos el número de palabras
		return numeroPalabras;
	}
}

//Cuenta las Palabras Claves Separado por la variable "Separador"
export function countKeyWords(texto, separador){
	if (texto==="" || texto===null){
		return 0;
	}else{
		//Troceamos el texto por los espacios
		var textoTroceado = texto.split(separador);
		//Contamos todos los trozos de cadenas que existen
		var numeroPalabras = textoTroceado.length;
		//Mostramos el número de palabras
		return numeroPalabras;
	}
}
      
//Convierte el Objeto en un formulario para enviar
export function objectToFormData(obj, form, level){
	var fd = form || new FormData();
	let formKey;
	for (let property in obj) {
		//if (obj.hasOwnProperty(property) && obj[property]) {
		if (level) {
			formKey = level + '[' + property + ']';
		} else {
			formKey = property;
		}

		if (obj[property]instanceof Date) {
			fd.append(formKey, obj[property].toISOString());
		} else if (typeof obj[property] === 'object' && !(obj[property]instanceof File)) {
			fd = objectToFormData(obj[property], fd, formKey)
		} else { // if it's a string or a File object
			if(obj[property]!=undefined){
				fd.append(formKey, obj[property])
			}
		}
		//}
	}
	return fd
}

//Crear Link para descargar archivo
export function createLinkFile(response, name="file.pdf"){
	//console.log(response)
	const url = window.URL.createObjectURL(new Blob([response.data]), {type: response.data.type});
	const link = document.createElement('a')
	link.href = url
	var nameFile = ""
	try {
		nameFile = response.headers["content-disposition"].split('filename=')[1]
	}catch{
		nameFile = name
	}
	nameFile = nameFile.replace(/['"]+/g, '')
	link.setAttribute('download', nameFile) //or any other extension
	document.body.appendChild(link)
	link.click();
	document.body.removeChild(link)
}
