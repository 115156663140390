import Vue from 'vue'
import Vuex from 'vuex'
import { getLocalUser } from '../helpers/auth';

const user = getLocalUser();

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: user,
    configuration: {}
  },
  getters:{
    currentUser(state){
      return state.currentUser;
    },
  },
  mutations: {
    loginSuccess(state, payload){
      var fecexpire = (new Date() / 1000) + payload.token.expires_in;
      var token = payload.token
      token.expire = fecexpire
      state.currentUser = Object.assign({}, payload.user, { token: token });
      localStorage.setItem("user", JSON.stringify(state.currentUser));
    },
    logout(state){
      localStorage.removeItem('user');
      state.currentUser = null;
    }
  },
  actions: {
    /*login(context){
      context.commit('login');
    }*/
  },
  modules: {
  }
})
