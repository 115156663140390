<template>
  <v-container fluid >
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card max-width="600">
          <v-toolbar
            dark dense
            color="primary"
            class="mb-1"
          >
          <v-spacer></v-spacer>
            <v-toolbar-title>
              Cargar Fechas 
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <p class="text-justify">
              El formato del archivo debe ser un <b>archivo de excel .xlsx</b>, debe contener un encabezado con las siguientes filas con sus respectivos valores: <br>
              <b>identificacion, cod_prog, fecha</b>
            </p>
            <v-form v-model="valid" ref="event">
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="editedItem.fileImport" accept=".xlsx" label="* Archivo"
                  :rules="rules.file" required prepend-icon="fa-file-excel" :show-size="1000"></v-file-input>
                </v-col>
              </v-row>
            </v-form>
            <v-dialog v-model="dialogError" max-width="500px" scrollable>
              <v-card>
                <v-system-bar dark color="primary" dense>
                  <v-icon>fa-exclamation-circle</v-icon> Log de Grabación
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mx-2 my-2" icon small v-on="on" @click.stop="dialogError=false">
                        <v-icon>fa-times</v-icon>
                      </v-btn>
                    </template>
                    <span>Cerrar</span>
                  </v-tooltip>
                </v-system-bar>
                <v-card-text>
                  <div class="mt-2">
                    <v-alert type="info" >
                      Se realizó la grabación correctamente, Sin embargo se encontraron los siguientes errores : 
                    </v-alert>
                    <v-alert v-for="(error, keyalert) in errors" 
                    type="error" :key="'alert'+keyalert">
                      {{ error }}
                    </v-alert>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" outlined @click="save">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { URL_APP } from "../../util/constants";
  export default{
    name: 'UploadDate',
    data() {
      return {
        dialogError: false,
        errors: [],
        rules: {
          file: [
            v => !!v || 'Campo requerido',
            v => !v || v.size < 2000000 || 'El Tamaño debe ser menor a 2MB',
          ],
        },
        editedItem: {fileImport: [], truncate: true},
        valid: true
      }
    },

    methods: {
      save () {
        if (this.valid){
          this.$root.$loadApp(true)
          var fd = new FormData();
          fd.append("fileImport", this.editedItem.fileImport)
          fd.append("truncate", this.editedItem.truncate)
          this.$http.post(URL_APP+'/api/sirog/importDate',fd,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          ).then(response => {
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
            this.$root.$loadApp(false)
          }).catch(error => {
            if(Array.isArray(error.response.data.errors)){
              this.errors = error.response.data.errors
              this.dialogError = true
            }else
              this.$root.$alert({text:"Almacenamiento no exitoso",color:"error",timeout:2000})
            this.$root.$loadApp(false)
          })
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },

    }
  }
</script>
