  <template>
    <div class="width=100%">
      <v-card color="#F1F3F4">
        <v-card-text>
           <v-container>
              <v-row>
                <v-col cols=12>
                  <v-autocomplete
                    v-model="form.expiration_date"
                    item-text="name"
                    item-value="id"
                    :items="data.expiration_dates"
                    @change="initialize(true)"
                    label="Reporte X Fecha Cohorte"
                  ></v-autocomplete>
                </v-col>
                <v-col cols=12 sm=6 md=4 lg=2>
                  <CardHeading title="Total Proyectos" :value="data.numProyects" :loading="loading" :color="'teal lighten-3'"/>
                </v-col>
                <v-col cols=12 sm=6 md=4 lg=2>
                  <CardHeading title="Proyectos 30 Dias" :value="data.numProyects30" :loading="loading" :color="'blue lighten-2'"/>
                </v-col>
                <v-col cols=12 sm=6 md=4 lg=2>
                  <CardHeading title="Proyectos Pendientes" :value="data.pendingProyects" :loading="loading" :color="'orange lighten-2'"/>
                </v-col>
                <v-col cols=12 sm=6 md=4 lg=2 v-for="(alert, indexAlert) in data.alertProyects" :key="'colalert'+indexAlert">
                  <CardHeading title="Pendientes Alerta" :value="alert.value" :loading="loading" :color="alert.color"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=12 sm=6 lg=4>
                  <PieProjects ref="pieChart" title="Proyectos X Tipo" :loading="loading" :id="'projectXTipo'"/>
                </v-col>
                <v-col cols=12 sm=6 lg=4>
                  <PieProjects ref="chartProjectMoments" title="Momentos X Proyecto" :loading="loading" :id="'projectMoments'"/>
                </v-col>
                <v-col cols=12 lg=8>
                  <PieProjects ref="chartDirector" title="Proyectos X Director" :loading="loading" :id="'pendingDirector'"/>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
      </v-card>
    </div>
</template>
<script>
import { URL_APP } from "../../util/constants";
import CardHeading from "./CardHeading";
import PieProjects from "./PieProjects";
  export default{
    name: 'Dashboard',
    components: {CardHeading, PieProjects},
    data() {
      return {
        data: {numProyects:0, numProyects30:0, pendingProjects:0, projectType:{}, expiration_dates:[]},
        form:{expiration_date:0},
        loading: true,
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize (update=false) {
        this.loading = true;
        this.$http.get(URL_APP+'/api/sirog/dashboard',{params:{expiration_date:this.form.expiration_date}})
        .then(response => {
          this.data = response.data;
          if(update){
            this.update()
          }else{
            this.$refs.pieChart.initialize(this.data.projectType)
            this.$refs.chartDirector.initialize(this.data.pendingDirector)
            this.$refs.chartProjectMoments.initialize(this.data.projectMoments)
          }
          this.$root.$loadApp(false)
        })
        .catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener los Dashboard",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
        this.loading = false;
      },

      update(){
          this.$refs.pieChart.update(this.data.projectType)
          this.$refs.chartDirector.update(this.data.pendingDirector)
          this.$refs.chartProjectMoments.update(this.data.projectMoments)
      }

    }
  }
</script>
