  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        sort-by="name"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Perfiles</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form v-model="valid" ref="roles">
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.name" label="Nombre"
                          :rules="rules.name" autofocus></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.description" label="Descripcion"
                          :rules="rules.description"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary" outlined @click="save">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editItem(item)" v-on="on" class="mr-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editPermission(item)" v-on="on" class="mr-1" color="orange lighten-1">fa-key</v-icon>
            </template>
            <span>Permisos</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog2" scrollable max-width="600px" persistent>
        <v-card>
          <v-toolbar dense color="primary" dark>
            <v-toolbar-title>Permisos del Perfil {{editedItem.name}}</v-toolbar-title>
            <v-spacer/>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                  <v-icon>fa-times</v-icon>
                </v-btn>
              </template>
              <span>Cerrar</span>
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;">
            <v-text-field
              v-model="search2"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              v-model="selected"
              :headers="headerPermissions"
              :items="permissions"
              item-key="id"
              :search="search2"
              show-select
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'fa-angle-double-left',
                lastIcon: 'fa-angle-double-right',
                prevIcon: 'fa-angle-left',
                nextIcon: 'fa-angle-right'
              }"
            >
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" outlined @click="close">Cancelar</v-btn>
            <v-btn color="primary" outlined @click="savePermissions">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  export default{
    data() {
      return {
        dialog: false,
        dialog2: false,
        search: '',
        search2: '',
        selected: [],
        rules: {
            name: [
                v => !!v || 'Campo requerido',
                v => v.length >= 5 || 'Mínimo 5 caracteres',
              ],
            description: [
                v => !!v || 'Campo requerido',
                v => v.length >= 5 || 'Mínimo 5 caracteres',
              ],
        },
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nombre', value: 'name' },
            { text: 'Descripcion', value: 'description' },
            { text: 'Actions', value: 'action', sortable: false },
        ],
        headerPermissions: [
            { text: 'Permiso', value: 'description' },
        ],
        typesPerfil: [],
        desserts: [],
        permissions: [],
        editedIndex: -1,
        editedItem: {id: 0, name: '', description: ''},
        defaultItem: {id: 0, name: '', description: ''},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      async initialize () {
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.getPermissions()
        await this.$http.get(URL_APP+'/api/roles/roles')
        .then(res => {
          this.desserts = res.data;
        })
        .catch(error => {
          this.$root.$alert({text:"Error al Obtener los Perfiles",color:"error",timeout:2000})
          console.error(error)
        });
        this.$root.$loadApp(false)
      },

      //Taer los Permisos
      async getPermissions () {
        var _this2 = this;
        let url = URL_APP+"/api/roles/permissions/role/"+this.editedItem.id
        this.selected = new Array();
        await this.$http.get(url)
        .then(res => {
          _this2.permissions = res.data;
          _this2.permissions.forEach(function(element) {
            if(element.active){
              _this2.selected.push(element);
            }
          });
        });
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      async deleteItem (item) {
        this.$root.$confirm("Eliminar Perfil", 'Esta seguro que desea eliminar el registro ?', { color: 'red' })
        .then(async (res) => {
          if(res){
            await this.$http.delete(URL_APP+"/api/roles/roles/" + item.id).then(response => {
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:3000})
            }).catch(error => {
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            });
          }else{
            this.$root.$alert({text:"¡ Acción Cancelada !",color:"warning",timeout:1000})
          }
        })
      },

      editPermission (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.getPermissions()
        this.dialog2 = true
      },

      close () {
        this.dialog = false
        this.dialog2 = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async savePermissions () {
        var url = URL_APP+"/api/roles/permissions/role";
        var params = {'role':this.editedItem.id, 'permissions':this.selected}
        await this.$http.post(url, params).then(res => {
          this.initialize();
          console.log(res)
          this.$root.$alert({text:"Permisos Actualizados Correctamente",color:"success",timeout:2000})
        }).catch(error => {
          this.$root.$alert({text:"Imposible Actualizar Registros",color:"success",timeout:2000})
          console.error(error)
       });
      },

      async save () {
        this.$refs.roles.validate()
        if (this.valid){
          var method1 = "POST"
          if (this.editedIndex > -1){
            //Editar Item
            method1 = "PUT"
          }
          await this.$http({
            method: method1,
            url: URL_APP+'/api/roles/roles',
            data: this.editedItem
          })
          .then(response => {
            this.close()
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
          this.$root.$loadApp(false)
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },

    }
  }
</script>
