<template>
  <div>
    <v-card :color="color"
      elevation="20"
      max-width="200"
      :loading="loading"
    >
      <v-card-text>
        <p class="caption">
          {{title}}
        </p>
        <p class="text--primary text-center font-weight-black text-h5">
          {{value}}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default{
    name: 'CardHeading',
    props: {
      title:String,
      value:Number,
      loading:Boolean,
      color:String
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style>

</style>