  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        sort-by="title"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Directores - Evaluadores</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
              <v-card>
                <v-toolbar dense color="primary" dark>
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <v-form v-model="valid" ref="third">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="editedItem.third.identification" label="* Identificación"
                          :rules="rules.identification" autofocus type="number" @blur="findId()"
                          :clearable="editedItem.id<=0" @click:clear="clearMessage()" @click:append-outer="findId()"
                          append-outer-icon="fa-search" :readonly="editedItem.id>0"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            :items="typeIdent"
                            item-value="id"
                            item-text="name"
                            :rules="rules.type_id" 
                            v-model="editedItem.third.type_id"
                            label="* Tipo Identificación"
                            id="typeIdent"
                            :readonly="editedItem.id>0"
                          ></v-select>
                        </v-col>
                        <template v-if="datos">
                          <v-col cols="12">
                            <v-text-field v-model="editedItem.third.name" label="* Nombre"
                            :rules="rules.name" :readonly="editedItem.id>0"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.third.mobile" label="* Teléfono"
                            :rules="rules.mobile" :readonly="editedItem.id>0"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.third.email" label="* Email"
                            :rules="rules.email" :readonly="editedItem.id>0"></v-text-field>
                          </v-col>
                        </template>
                        <v-col cols="12" md="6">
                          <v-select
                            :items="typeThird"
                            :rules="rules.type_third" 
                            v-model="editedItem.type_third"
                            label="* Tipo Tercero"
                            :readonly="editedItem.id>0"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-switch v-model="editedItem.belongs" label="Pertenece a la USTA" color="primary" :readonly="editedItem.id>0"></v-switch>
                        </v-col>
                        <v-col cols="12" md="6" v-if="editedItem.type_third=='Evaluador'">
                          <v-text-field v-model="editedItem.panel" label="* Panel" type="number" required
                            :rules="rules.panel" :readonly="editedItem.id>0"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                  <v-btn v-if="editedItem.id==0" color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.belongs="props">
          {{stateBelong(props.item.belongs)}}
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editItem(item)" v-on="on" class="mr-1" color="info">fa-eye</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:item.panel="props" >
          <v-edit-dialog
            :return-value.sync="props.item.panel"
            v-if="props.item.type_third=='Evaluador'"
            large
            ref="vdialog"
            persistent
            @save="savePanel(props.item)"
            @cancel="cancelPanel"
          > {{ props.item.panel }}
            <template v-slot:input>
              <v-text-field
                v-model="props.item.panel"
                label="Panel"
                :rules="rules.panel"
                single-line
                autofocus
                counter
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <div v-else>{{ props.item.panel }}</div>
        </template>

        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
</template>
<script>
import { URL_APP } from "../../util/constants";
  export default{
    name: 'ThirdDegreeWork',
    data() {
      return {
        dialog: false,
        search: '',
        datos: false,
        rules: {
          identification: [
            v => !!v || 'Campo requerido',
            v => v > 0 || 'Debe ser mayor a Cero(0)',
          ],
          panel: [
            v => !!v || 'Campo requerido',
            v => v > 0 || 'Debe ser mayor a Cero(0)',
          ],
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 5 || 'Mínimo 5 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          mobile: [
            v => !!v || 'Campo requerido',
          ],
          type_third: [
            v => !!v || 'Campo requerido',
          ],
          type_id: [
            v => !!v || 'Campo requerido',
          ],
          email: [
            v => (v || '').length > 0 || 'Email requerido',
            v => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(v) || 'Email Invalido.'
            }
          ],
        },
        headers: [
            { text: 'Id', value: 'third.identification' },
            { text: 'Nombre', value: 'third.name' },
            { text: 'Email', value: 'third.email' },
            { text: 'Teléfono', value: 'third.mobile' },
            { text: 'Tipo', value: 'type_third' },
            { text: 'Usta', value: 'belongs' },
            { text: 'Panel', value: 'panel' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
        typeIdent: [
          {id:'C', name:'Cédula de Ciudadanía'}, 
          {id:'T', name:'Tarjeta de Identidad'}, 
          {id:'I', name:'Cédula de Identidad'}, 
          {id:'P', name:'Tarjeta Pasaporte'}, 
          {id:'R', name:'Registro Civil'}, 
          {id:'E', name:'Cédula de Extranjería'}, 
        ],
        typeThird: [ 'Director', 'Evaluador', ],
        items: [],
        editedIndex: -1,
        editedItem: {id: 0, panel:0, type_third: '', belongs: false, third_id: 0, third: { id:0, identification:'', name:'', mobile:'', email:'', type_id:''}},
        defaultItem: {id: 0, panel:0, type_third: '', belongs: false, third_id: 0, third: { id:0, identification:'', name:'', mobile:'', email:'', type_id:''}},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Ver Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.$http.get(URL_APP+'/api/sirog/degree_thirds')
        .then(response => {
          this.items = response.data;
          this.$root.$loadApp(false)
        })
        .catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener los Terceros",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      clearMessage(){
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.third = Object.assign({}, this.defaultItem.third)
        this.editedIndex = -1
        this.datos = false
      },

      findId(){
        if(this.editedItem.id<=0){
          if(this.editedItem.third.identification==0 || this.editedItem.third.identification==''){
            this.clearMessage();
          }else{
            this.$root.$loadApp(true)
            this.$http.get(URL_APP+"/api/sirog/degree_third", {params:{"id":this.editedItem.third.identification}} )
            .then(response => {
              this.datos = true
              this.$root.$loadApp(false)
              if(typeof response.data.id != "undefined"){
                this.editedItem.third = response.data
                this.editedItem.third_id = response.data.id
              }else{
                this.$root.$alert({text:"No encontrado, por favor llenar los datos",color:"error",timeout:3000})
                document.getElementById("typeIdent").focus();
              }
            }).catch(error => {
              this.$root.$alert({text:error.response.data.message,color:"error",timeout:3000})
              this.$root.$loadApp(false)
            })
          }
        }
      },

      stateBelong (stateIt) {
        return stateIt ? 'SI' : 'NO'
      },

      editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        this.datos = true
      },

      deleteItem (item) {
        var url = URL_APP+"/api/sirog/degree_thirds/" + item.id;
        this.$root.$confirm('Eliminar '+item.type_third, 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.$http.delete(url)
            .then(response => {
              console.log(response)
              this.initialize();
              this.$root.$alert({text:"¡ Registro Eliminado !",color:"success",timeout:2000})
            })
            .catch(error => {
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:2000})
            });
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.clearMessage()
        }, 300)
      },

      cancelPanel () {
        this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:2000})
      },

      savePanel (item) {
        if(item.panel>0){
            this.$http.put(URL_APP+'/api/sirog/degree_thirds', 
              item
            ).then(response => {
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
            }).catch(error => {
              console.error(error)
              this.$root.$alert({text:"Almacenamiento no exitoso",color:"error",timeout:2000})
            })
        }else{
          this.$root.$alert({text:"Error en Panel",color:"error",timeout:2000})
        }
        console.log(item)
      },
      
      save () {
        this.$refs.third.validate()
        if (this.valid){
            this.$http.post(URL_APP+'/api/sirog/degree_thirds', 
              this.editedItem
            ).then(response => {
              this.close()
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
            }).catch(error => {
              console.error(error)
              this.$root.$alert({text:"Almacenamiento no exitoso",color:"error",timeout:2000})
            })
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },
    }
  }
</script>
