  <template>
    <div>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="degreeWork.associated"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Integrantes</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <template v-if="!searchForm.found">
                        <v-col cols="12" >
                          <v-text-field v-model="searchForm.id" label="Identificación / Código"
                          @keyup.enter="findId"
                          id="searchFormId"
                          append-outer-icon="fa-greater-than"
                          required
                          @click:append-outer="findId"></v-text-field>
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="12" md="6">
                          <span class="font-weight-bold">Identificación: </span>
                          <span class="info--text">{{editedItem.identification}}</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <span class="font-weight-bold">Nombre: </span>
                          <span class="info--text">{{editedItem.name}}</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <span class="font-weight-bold">Email: </span>
                          <span class="info--text">{{editedItem.email}}</span>
                        </v-col>
                        <v-col cols="12" md="6">
                          <span class="font-weight-bold">Programas: </span>
                          <span class="info--text"><template v-for="program in editedItem.programs">{{program.NOM_UNIDAD}} - </template></span>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                  <v-btn v-if="!searchForm.found" color="primary darken-1" outlined @click="findId">Buscar</v-btn>
                  <v-btn v-else color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom v-if="currentUser.email != item.email">
            <template v-slot:activator="{ on }">
              <v-icon small @click="deleteItem(item)" v-on="on">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  export default{
    name: 'Associated',
    props: {
      degreeWork: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        dialog: false,
        searchForm: {found: false, id: ''},
        headers: [
          { text: 'Identificacion', value: 'identification' },
          { text: 'Nombre', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Teléfono', value: 'phone' },
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        editedIndex: -1,
        editedItem: {identification: 0, name: '', mobile: '', email:'', phone:'', programs:[]},
        defaultItem: {identification: 0, name: '', mobile: '', email:'', phone:'', programs:[]},
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    methods: {

      findId(){
        if(this.searchForm.id==''){
          this.$root.$alert({text:"Identificación Obligatoria",color:"error",timeout:3000})
          document.getElementById('searchFormId').focus()
        }else{
          this.$root.$loadApp(true)
          this.$http.get(URL_APP+'/api/sirog/verifyAssociated',{params:{"type":"Estudiante", "id": this.searchForm.id}})
          .then(response => {
            this.searchForm.found = true
            this.editedItem = response.data
            this.$root.$loadApp(false)
          }).catch(error => {
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:3000})
            this.$root.$loadApp(false)
          })
        }
      },

      deleteItem (item) {
        this.$root.$confirm('Eliminar Integrante', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            const index = this.degreeWork.associated.indexOf(item)
            this.degreeWork.associated.splice(index, 1)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.programs = Array()
          this.searchForm.id = ''
          this.searchForm.found = false
          this.editedIndex = -1
        }, 300)
      },

      save () {
        var valid = true
        var _this2 = this
        //Revisar si ya existe
        if (Array.isArray(this.degreeWork.associated)) {
          this.degreeWork.associated.forEach(function(associated){
            if(associated.identification == _this2.editedItem.identification){
              _this2.$root.$alert({text:"Integrante ya Existe",color:"error",timeout:2000})
              valid = false
            }
          })
        }
        if(valid){
          this.degreeWork.associated.push(this.editedItem)
          this.close()
        }
      },
    }
  }
</script>
