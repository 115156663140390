<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12 pa-3">
          <v-card-text>
            <div class="layout column align-center">
              <img class="primary" src="../../assets/santoto.png" alt="USTAB" width="90%">
              <h4 class="flex my-4 primary--text">Recupera tu contraseña</h4>
            </div>                
            <v-form v-model="valid" ref="recover" v-on:submit.prevent="false" v-if="!formRecover.user">
              <v-text-field append-icon="fa-at" @keyup.enter="nextMail" name="login" label="Correo Electrónico" type="text" v-model="form.email" :rules="rules.emailRules" required autofocus></v-text-field>
              <!--{{-- CAPTCHA --}}-->
              <vue-recaptcha
                ref="invisibleRecaptcha"
                @verify="onVerify"
                @expired="onExpired"
                :sitekey="sitekey">
              </vue-recaptcha>
            </v-form>
            <v-form v-model="valid" ref="recover" v-on:submit.prevent="false" v-else>
              <span>Se encontraron los Siguientes Correos de recuperación</span>
              <v-radio-group v-model="formRecover.mail" >
                <v-radio
                  v-for="mail in userRecover.mails"
                  :key="mail"
                  :label="mail"
                  :value="mail"
                ></v-radio>
              </v-radio-group>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container grid-list-md text-center>
              <v-row wrap>
                <v-col cols="6" class="my-2">
                  <v-btn text color="orange darken-1" to="/login">Cancelar</v-btn>
                </v-col>
                <v-col cols="6" class="my-2" v-if="!formRecover.user">
                  <v-btn color="primary" @click="nextMail" :loading="loading" :disabled="disableButton">Siguiente</v-btn>
                </v-col>
                <v-col cols="6" class="my-2" v-else>
                  <v-btn color="primary" @click="recoverMail" :loading="loading">Enviar Correo</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//import {ofuscaMail} from '@/js/util';
import { URL_APP } from "../../util/constants";
import VueRecaptcha from 'vue-recaptcha'
import * as constants from '../../util/constants'
export default {
    name: 'recover',
    components: {VueRecaptcha},
    data() {
        return {
            sitekey: constants.KEY_CLIENT, 
            loading: false,
            disabledSubmit: true,
            form:{
                email: '',
                captcha: ''
            },
            formRecover:{
                user: '',
                k: constants.KEY_CLIENT,
                mail: ''
            },
            userRecover:[],
            radioMail: null,
            error: null,
            rules: {
              min: v => v.length >= 8 || 'Mínimo 8 caracteres',
              emailRules: [
                v => !!v || 'Correo Requerido',
                v => /.+@.+\..+/.test(v) || 'Correo Invalido',
              ],
            },
            valid: true,
        }
    },
    props: {
        source: String
    },
    computed: {
        disableButton: function (){
            if(this.valid){
              if(this.form.captcha!=""){
                return false
              }else{
                return true
              }
            }else{
              return true
            }
        }
    },
    mounted() { 
      let recaptchaScript = document.createElement ('script'); 
      recaptchaScript.setAttribute('src', "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"); 
      document.head.appendChild(recaptchaScript); 
    },
    methods: {
        nextMail(){
            if (!this.disableButton) {
              this.loading = true;
              this.$http.post(URL_APP+'/api/password/findemail', this.form)
              .then((res)=>{
                  this.userRecover = res.data;
                  this.formRecover.user = res.data.user;
                  this.formRecover.mail = res.data.mails[0];
                  this.loading = false;
                  this.resetRecaptcha()
              })
              .catch((error)=>{
                this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
                this.loading = false;
                this.resetRecaptcha()
              });
            }else{
              this.$root.$alert({text:'Por favor revise los Errores',color:"error",timeout:2000})
            }
        },
        recoverMail(){
          this.loading = true;
              this.$http.post(URL_APP+'/api/password/token', this.formRecover)
              .then((res)=>{
                  this.$root.$alert({text:res.data.message,color:"success",timeout:2000})
                  this.$router.push({path: '/login'});
                  this.loading = false;
              })
              .catch((error)=>{
                  this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
                  this.loading = false;
              });
        },
        onVerify: function (response) {
          this.form.captcha = response;
        },
        onExpired: function () {
          this.form.captcha = "";
        },
        resetRecaptcha () {
          this.$refs.invisibleRecaptcha.reset() // Direct call reset method
          this.onExpired()
        }
    }
}
</script>
