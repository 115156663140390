  <template>
    <div>
      <v-card min-height="400px">
        <v-toolbar
          dark dense
          color="primary"
          class="mb-1"
        >
        <v-spacer></v-spacer>
          <v-toolbar-title>
            Trabajos de Grado
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="d-none d-sm-flex">
            <v-switch color="red" dense hide-selected class="mx-5" inset
              v-model="pending" hide-details
              label="Pendientes"
              @change="changePending()"
            ></v-switch>
          </div>
          <div class="d-flex d-sm-none">
            <v-switch color="red" dense hide-selected class="mx-5" inset
              v-model="pending" hide-details
              label=""
              @change="changePending()"
            ></v-switch>
          </div>
        </v-toolbar>
        <v-card-text>
          <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize" id="filterQuery"></FilterQuery>
          <DataIterator :items="items" @historyItem="historyItem" @newMoment="newMoment" @getAttacheds="getAttacheds"></DataIterator>
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
        <v-card>
          <v-toolbar dark color="primary" dense>
            <v-toolbar-title>
              <template>{{ formTitle }}
              </template>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                  X
                </v-btn>
              </template>
              <span>Cerrar</span>
            </v-tooltip>
          </v-toolbar>

          <v-card-text>
            <v-form v-model="valid" ref="event">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedItem.program_id" 
                      item-value="id"
                      item-text="name"
                      :rules="rules.program_id"
                      :items="programs"
                      label="* Programa"
                      placeholder="Seleccione el Programa"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedItem.modality_id" 
                      item-value="id"
                      item-text="name"
                      :rules="rules.modality_id"
                      :items="modalities"
                      label="* Modalidad"
                      placeholder="Seleccione la Modalidad"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.title" label="* Titulo"
                    :rules="rules.title" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input multiple 
                      v-model="editedItem.files"
                      show-size counter
                      label="Seleccione Archivo(s)"
                      clearable
                      small-chips
                      accept="application/pdf,application/msword"></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <!--Estudiantes-->
                    <Associated :degreeWork="editedItem"></Associated>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
            <v-btn color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Dialogo de Historial-->
      <HistoryDegreeWork :editedItem="editedItem" :dialog="dialogH" @close="close" @getAttacheds="getAttacheds"></HistoryDegreeWork>
      <!--Dialogo de Momento-->
      <Moment :editedItem="editedItem" :dialog="dialogM" :moment="moment" @close="close" ref="newMoment"></Moment>
    </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  import { objectToFormData, createLinkFile } from '../../util/functions';
  import FilterQuery from '../general/FilterQuery';
  import Associated from './Associated';
  import HistoryDegreeWork from './HistoryDegreeWork';
  import DataIterator from './DataIterator';
  import Moment from './Moment';
  export default{
    name: 'DegreeWork',
    components:{ Associated, HistoryDegreeWork, DataIterator, FilterQuery, Moment },
    data() {
      return {
        dialog: false,
        dialogH: false,
        dialogH2: false,
        dialogM: false,
        pending: false,
        search: '',
        rules: {
          title: [
            v => !!v || 'Campo requerido',
            v => v.length >= 3 || 'Mínimo 3 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          program_id: [
            v => !!v || 'Campo Requerido',
          ],
          modality_id: [
            v => !!v || 'Campo Requerido',
          ],
        },
        filters:[
          {field:{field:'state', name:'Estado', type:'select', options:["Activo", "Finalizado"]}, type:'Igual', value_start:'Activo', value_end:''}
        ],
        fieldFilters:[
          {field:'title', name:'Titulo', type:'text'},
          {field:'modality.name', name:'Modalidad', type:'text'},
          {field:'program.name', name:'Programa', type:'text'},
          {field:'created_at',  name:'Fecha Registro', type:'date'},
          {field:'state', name:'Estado', type:'select', options:["Activo", "Finalizado"]},
        ],
        items: [],
        programs: [],
        modalities:[],
        moment:{},
        moments:[],
        editedIndex: -1,
        editedItem: {id: 0, title: '', modality_id: '', program_id:'', files: [], associated:[], next_moment:{name:''}},
        defaultItem: {id: 0, title: '', modality_id: '', program_id:'', files: [], associated:[], next_moment:{name:''}},
        valid: true,
        itemsPerPageArray: [200, 400, 500],
        sortDesc: false,
        page: 1,
        itemsPerPage: 200,
        sortBy: 'name',
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Registrar Trabajo de Grado' : 'Ver / Editar Trabajo de Grado'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      },
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      changePending(){
        this.initialize();
      },

      initialize () {
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.$http.get(URL_APP+'/api/sirog/degreeworks', {
          params: {
            filters: this.filters,
            pending: this.pending
          }
        })
        .then(response => {
          this.items = response.data;
          this.$root.$loadApp(false)
        })
        .catch(error => {
          this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      historyItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogH = true
      },

      newMoment (item, moment) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.moment = Object.assign({}, moment)
        this.dialogM = true
        setTimeout(() => {
          this.$refs.newMoment.initialize()
        }, 300)
      },

      getAttacheds(degree,moment) {
        var report = {degree:degree, moment:moment}
        var url = URL_APP+"/api/sirog/getattached/" + JSON.stringify(report);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => {
          console.error(error)
          this.$root.$alert({text:"Error al Obtener los archivos",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },

      close () {
        this.dialog = false
        this.dialogH = false
        this.dialogH2 = false
        this.dialogM = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.associated = Array();
          this.editedItem.files = Array();
          this.editedIndex = -1
        }, 300)
        this.initialize();
      },

      save () {
        if (this.valid){
          this.$root.$loadApp(true)
          const formData = objectToFormData(this.editedItem)
          var method1 = "post"
          if (this.editedIndex > -1) {
            //Editar Item
            method1 = "put"
          }
          this.$http({
            method: method1,
            url: URL_APP+'/api/sirog/degreeworks',
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            this.close()
            this.$root.$loadApp(false)
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$loadApp(false)
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },

      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    }
  }
</script>
