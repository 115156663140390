  <template>
    <div>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="moment.evaluators"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Evaluadores</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="newEv()">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">Seleccionar Evaluadores</span>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="search"
                    append-icon="fa-search"
                    label="Buscar"
                    id="placeSearch"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-data-table
                    v-model="selected"
                    :headers="headers2"
                    :items="thirds"
                    item-key="third.id"
                    :search="search"
                    show-select
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom v-if="currentUser.email != item.email">
            <template v-slot:activator="{ on }">
              <v-icon small @click="deleteItem(item)" v-on="on">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
</template>
<script>
  export default{
    name: 'Evaluator',
    props: {
      moment: {
        type: Object,
        default: null
      },
      thirds: {
        type: Array,
        default: null
      },
    },
    data() {
      return {
        dialog: false,
        search: '',
        selected: [],
        headers: [
          { text: 'Identificacion', value: 'identification' },
          { text: 'Nombre', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Teléfono', value: 'mobile' },
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        headers2: [
          { text: 'Identificacion', value: 'third.identification' },
          { text: 'Nombre', value: 'third.name' },
          { text: 'Email', value: 'third.email' },
          { text: 'Teléfono', value: 'third.mobile' },
        ],
        editedIndex: -1,
        editedItem: {id:0, name:'', email:'', mobile:''},
        defaultItem: {id: 0, name:'', email:'', mobile:''},
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    methods: {

      newEv(){
        this.dialog=true
        var _this2 = this
        this.selected = []
        if (Array.isArray(this.thirds) ) {
          this.thirds.forEach(function(third){
            if (Array.isArray(_this2.moment.evaluators) ) {
              _this2.moment.evaluators.forEach(function(evaluator){
                if(third.third.id == evaluator.id){
                  _this2.selected.push(third)
                }
              })
            }
          })
        }
      },

      deleteItem (item) {
        this.$root.$confirm('Eliminar Evaluador', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            const index = this.moment.evaluators.indexOf(item)
            this.moment.evaluators.splice(index, 1)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      selectEv(){
        this.thirds2 = Array()
        var _this2 = this
        if (Array.isArray(this.thirds) ) {
          //Valores de tercero
          this.thirds.forEach(function(third){
            if(_this2.editedItem.id==third.third.id){
              _this2.editedItem.identification=third.third.identification
              _this2.editedItem.name=third.third.name
              _this2.editedItem.email=third.third.email
              _this2.editedItem.mobile=third.third.mobile
            }
          })
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        var evaluators = []
        //Revisar si ya existe
        if (Array.isArray(this.selected)) {
          this.selected.forEach(function(evaluator){
            evaluators.push(evaluator.third)
          })
        }
        console.log(this.selected)
        console.log(evaluators)
        this.moment.evaluators = evaluators
        console.log(this.moment.evaluators)
        this.close()
      },
    }
  }
</script>
