<template>

  <v-app-bar color="primary"
    dark
    app
    :clipped-left="$vuetify.breakpoint.mdAndUp"
  >
    <v-tooltip bottom v-if="currentUser">
      <template v-slot:activator="{ on }">
        <v-app-bar-nav-icon v-on="on" @click="handleDrawerToggle"></v-app-bar-nav-icon>
      </template>
      <span>Menu</span>
    </v-tooltip>
    <template v-else>
      <v-tooltip bottom>
        <template v-slot:activator="{ on3 }">
          <v-btn class="mx-2 my-2" icon v-on="on3" to="/">
            <v-icon dark>fa-university</v-icon>
          </v-btn>
        </template>
        <span>Inicio</span>
      </v-tooltip>
    </template>
    <v-toolbar-title>
      <span class="hidden-sm-and-down">Sistema Institucional de Registro Opción de Grado</span>
      <span class="hidden-md-and-up">SIROG</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- Comentado Mientras se Implementan las notificaciones
    <v-menu offset-y origin="center center" class="elevation-1" :nudge-bottom="14" transition="scale-transition">

      <template v-slot:activator="{ on }">
        <v-btn icon
          v-on="on"
        >
          <v-badge color="red" overlap>
            <span slot="badge" v-if="false">3</span>
            <v-icon medium>fa-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list v-if="false">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          link
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <notification-list></notification-list> 
        
    </v-menu>-->
    <template v-if="currentUser">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="mx-2 my-2" icon v-on="on" @click="handleFullScreen()">
            <v-icon dark>fa-expand</v-icon>
          </v-btn>
        </template>
        <span>Pantalla Completa</span>
      </v-tooltip>
      <v-menu offset-y origin="center center" class="elevation-1" :nudge-bottom="14" transition="scale-transition">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon v-on="{ ...tooltip, ...menu }" >
                <v-avatar size="30px" v-if="false">
                  <img src="/static/avatar/man_4.jpg" :alt="currentUser.name"/>
                </v-avatar>
                <v-icon v-else>fa-user-circle</v-icon>
              </v-btn>
            </template>
            <span>{{currentUser.name}}</span>
          </v-tooltip>
        </template>
        <v-list flat>
          <v-list-item
            v-for="(item, i) in items"
            :to="!item.href ? { name: item.name } : null" 
            :href="item.href" 
            :key="i"
            @click="item.click"
          >
            <v-list-item-icon>
              <v-icon v-if="item.icon">{{item.icon}}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-else>
      <!--Web-->
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-on="{ ...tooltip }" text icon href="http://www.ustabuca.edu.co" target="_blank">
            <v-icon>fa-globe</v-icon>
          </v-btn>
        </template>
        <span>Página Web</span>
      </v-tooltip>
      <!--Menú de redes Sociales-->
      <div class="d-flex d-sm-none">
        <v-menu offset-y origin="center center" class="elevation-1" :nudge-bottom="14" transition="scale-transition">
          <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-on="{ ...tooltip, ...menu }" text>
                  <v-icon>fab fa-hubspot</v-icon>
                </v-btn>
              </template>
              <span>Redes Sociales</span>
            </v-tooltip>
          </template>
          <v-list flat>
            <v-list-item
              v-for="(red, i) in redes"
              :href="red.href" 
              :key="i"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon v-if="red.icon">{{red.icon}}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="red.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="d-none d-sm-flex">
        <v-tooltip bottom 
          v-for="(red, i) in redes"
          :key="i"
        >
          <template v-slot:activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip }" text icon :href="red.href" target="_blank">
              <v-icon>{{ red.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ red.title }}</span>
        </v-tooltip>
      </div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-on="{ ...tooltip }" text to="/login">
            <v-icon left >fa-user-alt</v-icon><span class="d-none d-sm-flex">Inicio Sesión</span>
          </v-btn>
        </template>
        <span>Iniciar Sesión</span>
      </v-tooltip>
    </template>
  </v-app-bar>
</template>
<script>
//import NotificationList from '@/components/widgets/list/NotificationList';
import Util from '../util';
export default {
  name: 'app-toolbar',
//   components: {
    // NotificationList
//   },
  props: {
    show_drawer: Boolean
  },
  data: () => ({
    items: [
      //Comentado Mientras se implementan
      /*{
        icon: 'fa-user-circle',
        href: '#',
        title: 'Perfil',
        click: (e) => {
          console.log(e);
        }
      },
      {
        icon: 'fa-user-cog',
        href: '#',
        title: 'Configuración',
        click: (e) => {
          console.log(e);
        }
      },*/
      {
        icon: 'fa-exchange-alt',
        href: '#',
        title: 'Cambiar Contraseña',
        click: (e) => {
          console.log(e)
          window.getApp.$emit('APP_CHPASSWD');
        }
      },
      {
        icon: 'fa-sign-in-alt',
        href: '#!',
        title: 'Salir',
        click: (e) => {
          console.log(e)
          window.getApp.$emit('APP_LOGOUT');
        }
      }
    ],
    redes:[
      {
        icon: 'fab fa-facebook-f',
        href: 'https://es-la.facebook.com/SANTOTOBGA/',
        title: 'Facebook',
      },
      {
        icon: 'fab fa-youtube',
        href: 'https://www.youtube.com/channel/UCZT4JaKHf7RcJ4FNLs4LFhg',
        title: 'Youtube',
      },
      {
        icon: 'fab fa-twitter',
        href: 'https://twitter.com/santotobga',
        title: 'Twitter',
      },
      {
        icon: 'fab fa-instagram',
        href: 'https://www.instagram.com/santotobucaramanga/',
        title: 'Instagram',
      },
      {
        icon: 'fab fa-linkedin-in',
        href: 'https://co.linkedin.com/company/universidad-santo-tomas-bucaramanga',
        title: 'Linkedin',
      },
      {
        icon: 'fa-envelope',
        href: 'https://login.microsoftonline.com/',
        title: 'Correo Institucional',
      },
    ],
  }),
  computed: {
    toolbarColor () {
        return this.$vuetify.options.extra.mainNav;
    },
    currentUser(){
        return this.$store.getters.currentUser
    }
  },
  methods: {
    handleDrawerToggle() {
      this.$emit("update:drawer",!this.show_drawer);
    },
    handleFullScreen () {
      Util.toggleFullScreen();
    }
  }
};
</script>
<style>
  a:link,a:visited,a:hover{
    text-decoration: none;
  }
</style>
