<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" scrollable persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            Movimientos X Fecha
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col cols="4" align="center">
                <span class="font-weight-bold">Programa</span>
              </v-col>
              <v-col cols="8" align="center">
                <v-autocomplete
                  v-model="report.program"
                  item-text="name"
                  item-value="id"
                  :items="programs"
                  chips
                  label="Programa(s)"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" align="center">
                <span class="font-weight-bold">Fecha</span>
              </v-col>
              <v-col cols="8" align="center">
                <v-dialog
                  ref="dialogS"
                  v-model="dialogDate"
                  :return-value.sync="report.date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="report.date"
                      label="Fecha Movimientos"
                      prepend-icon="fa-calendar-alt"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="report.date" scrollable :max="fechaHoy()">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialogDate = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.dialogS.save(report.date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { URL_APP } from "../../../util/constants";
  import { createLinkFile } from '../../../util/functions';
  export default{
    name: 'DegreeWorkDate',
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        dialogDate: false,
        programs: [],
        report:{date: '', program:[]}
      }
    },

    computed: {
    },

    watch: {
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.report.date = this.fechaHoy()
        this.getPrograms()
      },

      close () {
        this.$emit('close')
      },

      fechaHoy(){
        return new Date().toISOString().substr(0, 10)
      },

      getPrograms () {
        this.$http.get(URL_APP+'/api/sirog/selprogramsdw')
        .then(response => {
          this.programs = response.data;
        })
        .catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener los Programas",color:"error",timeout:2000})
        });
      },

      save () {
        var url = URL_APP+"/api/sirog/date/" + JSON.stringify(this.report);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => { console.error(error) 
          this.$root.$alert({text:"Error al Obtener el Reporte",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },
    }
  }
</script>
