<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable>
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>
          <template>{{editedItem.title}}
          </template>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
              X
            </v-btn>
          </template>
          <span>Cerrar</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-timeline align-top dense>
          <v-timeline-item
            v-for="(moment, i) in editedItem.moments"
            :key="i"
            :color="moment.moment.color"
            :fill-dot="editedItem.moments>1"
          >
            <v-card
              :color="moment.moment.color"
              dark
              max-width="500"
            >
              <v-card-title class="title">{{moment.moment.name}} <span v-if="moment.moment.end"> - {{editedItem.score}}</span></v-card-title>
              <v-card-text class="white text--primary">
                <div><b>Fecha: </b>{{moment.created_at}}</div>
                <div><b>Usuario Registro: </b>{{moment.user.name}}</div>
                <div v-if="moment.date_support">
                  <div><b>Fecha de Sustentación: </b>{{moment.date_support}}</div>
                </div>
                <div v-if="moment.moment.begin==true">
                  <div><b>Programa: </b>{{editedItem.program.name}}</div>
                  <div><b>Modalidad: </b>{{editedItem.modality.name}}</div>
                  <div><b>Miembros: </b></div>
                </div>
                <div v-if="moment.thirds.length > 0">
                  <ul>
                    <li v-for="third in moment.thirds" :key="third.id">
                      {{third.pivot.type_third}} - {{third.name}}  <v-icon :color="third.pivot.active?'green':'red'">{{third.pivot.active?'fa-check':'fa-times'}}</v-icon>
                    </li>
                  </ul>
                </div>
                <div v-if="moment.attacheds.length > 0">
                  <span><b>Adjuntos: </b></span>
                  <v-chip small :color="moment.moment.color" @dblclick="getAttacheds(null,moment.id)">Descargar ({{moment.attacheds.length}})</v-chip>
                </div>
                <div v-if="moment.observation!=null">
                  <span><b>Observación: </b></span>
                  <span>{{moment.observation}}</span>
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'HistoryDegreeWork',
    props: {
      editedItem: {
        type: Object,
        default: null
      },
      dialog: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      close(){
        this.$emit('close')
      },
      getAttacheds(degree,moment) {
        this.$emit('getAttacheds',degree,moment)
      },
    },
  }
</script>