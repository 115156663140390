  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        sort-by="name"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Usuarios</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form v-model="valid" ref="form1">
                    <v-container grid-list-md>
                      <v-row >
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.id_sectional"
                            :items="sectionals"
                            :rules="rules.sectional"
                            item-text="name"
                            item-value="id"
                            label="* Seccional"
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field type="number" v-model="editedItem.identity" label="Identidad"
                          :rules="rules.identity" autofocus></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.name" label="Nombre"
                          :rules="rules.name" autofocus></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.email" label="Correo Electrónico"
                          :rules="rules.email" autofocus></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.roles"
                            :items="roles"
                            item-text="name"
                            item-value="name"
                            attach
                            chips
                            label="Roles"
                            multiple
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.password" label="Contraseña"
                          :rules="rules.password" type="password"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-switch v-model="editedItem.active" label="Estado"></v-switch>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary" outlined @click="save">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.roles="{ item }">
          <v-chip color="secondary" v-for="(rol, indexRol) in item.roles" :key="indexRol" dark>{{ rol.name }}</v-chip>
        </template>
        <template v-slot:item.active="props">
          {{stateItem(props.item.active)}}
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editItem(item)" v-on="on" class="mr-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <!--<v-btn color="primary" @click="initialize">Refrescar</v-btn> -->
        </template>
      </v-data-table>
    </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  export default{
    data() {
      return {
        dialog: false,
        search: '',
        selected: [],
        rules: {
            name: [
              v => !!v || 'Campo requerido',
              v => v.length >= 5 || 'Mínimo 5 caracteres',
            ],
            email: [
              v => !!v || 'Campo Requerido'
            ],
            identity: [
              v => !!v || 'Campo Requerido'
            ]
        },
        headers: [
            { text: 'Seccional', value: 'sectional.name' },
            { text: 'Identidad', value: 'identity' },
            { text: 'Nombre', value: 'name' },
            { text: 'Email', value: 'email' },
            { text: 'Roles', value: 'roles' },
            { text: 'Estado', value: 'active' },
            { text: 'Actions', value: 'action', sortable: false },
        ],
        desserts: [],
        sectionals: [],
        roles: [],
        editedIndex: -1,
        editedItem: {id_sectional:'', id: 0, identity: 0, name: '', email: '', roles: [], password: '', active: true},
        defaultItem: {id_sectional:'', id: 0, identity: 0, name: '', email: '', roles: [], password: '', active: true},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      async initialize () {
        if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
        this.getRoles()
        this.getSectionals()
        await this.$http.get(URL_APP+'/api/users/users')
        .then(res => {
          this.desserts = res.data;
          this.$root.$loadApp(false)
        });
      },

      //Taer los Roles
      async getRoles () {
        await this.$http.get(URL_APP+"/api/roles/roles")
        .then(res => {
          this.roles = res.data;
        });
      },

      //Taer las Seccionales
      getSectionals () {
        this.$http.get(URL_APP+"/api/selsectionals")
        .then(res => {
          this.sectionals = res.data;
        });
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      async deleteItem (item) {
        this.$root.$confirm("Eliminar Usuario", 'Esta seguro que desea eliminar el registro ?', { color: 'red' })
        .then(async (res) => {
          if(res){
            await this.$http.delete(URL_APP+"/api/users/users/" + item.id).then(response => {
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:3000})
            }).catch(error => {
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            });
          }else{
            this.$root.$alert({text:"¡ Acción Cancelada !",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        this.$refs.form1.validate()
        if (this.valid){
          var method1 = "POST"
          if (this.editedIndex > -1){
            //Editar Item
            method1 = "PUT"
          }
          await this.$http({
            method: method1,
            url: URL_APP+'/api/users/users',
            data: this.editedItem
          })
          .then(response => {
            this.close()
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          })
          this.$root.$loadApp(false)
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },
    }
  }
</script>
